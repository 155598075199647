import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    Button,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@material-ui/core";

import { toAbsoluteUrl } from "../../../../../_metronic";
import KTAvatar from "../../../../../_metronic/_assets/js/avatar";
import { upload_event_pic } from "../../../../crud/event.crud";

import * as eventsStore from "../../../../store/ducks/events.duck";

const useDlgStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: colors.blue[100],
        color: colors.blue[600]
    },
    formControl: {
        minWidth: 250,
        maxWidth: 400
    },
    formLangControl: {
        minWidth: 150,
        maxWidth: 300
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    fullWidth: {
        width: "100%"
    }
}));

function Room(props) {
    const { room, languages, trans, values, index, handleRemove, handleChange, intl } = props;
    const { rooms, setRooms } = props.pstate;
    const classes = useDlgStyles();

    useEffect(() => {
        setRooms(prev => {
            const oldRooms = JSON.parse(JSON.stringify(prev));
            oldRooms.forEach((room, i) => {
                if (index === i) {
                    room.output_lang_id = values[`output_lang_id_${index}`] ?? "";
                    room.interpreter_id = values[`interpreter_id_${index}`] ?? "";
                    room.liquid_url = values[`liquid_url_${index}`] ?? "";
                    room.wss_url = values[`wss_url_${index}`] ?? "";
                }
            });
            return oldRooms;
        });
    }, [values, index, setRooms]);

    const handleAddLanguage = e => {
        setRooms([
            ...rooms,
            {
                output_lang_id: "",
                interpreter_id: "",
                liquid_url: "",
                wss_url: ""
            }
        ]);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
                <FormControl className={classes.formLangControl}>
                    <InputLabel id={`out-lang-checkbox-label_${index}`}>
                        <FormattedMessage id="EVENT.FIELD.OUTPUT_LANG" />
                    </InputLabel>
                    <Select
                        labelId={`out-lang-checkbox-label_${index}`}
                        id={`out-lang-checkbox_${index}`}
                        value={room ? room.output_lang_id : ""}
                        onChange={handleChange}
                        name={`output_lang_id_${index}`}
                    >
                        {languages.map(row => (
                            <MenuItem key={row.id} value={row.id}>
                                {row.lang_label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <FormControl className={classes.formLangControl}>
                    <InputLabel id={`input-interpreter-checkbox-label_${index}`}>
                        <FormattedMessage id="EVENT.FIELD.INTERPRETER" />
                    </InputLabel>
                    <Select
                        labelId={`input-interpreter-checkbox-label_${index}`}
                        id={`input-interpreter-checkbox_${index}`}
                        value={room ? room.interpreter_id : ""}
                        onChange={handleChange}
                        name={`interpreter_id_${index}`}
                    >
                        {trans.map(row => (
                            <MenuItem key={row.id} value={row.id}>
                                {row.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                xs={12}
                sm={2}
                container
                alignItems="flex-end"
                justify="flex-end"
                direction="row"
            >
                <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    size="small"
                    onClick={e => handleRemove(index)}
                >
                    <DeleteIcon />
                </IconButton>
                <IconButton
                    className={classes.button}
                    aria-label="Add"
                    size="small"
                    onClick={handleAddLanguage}
                >
                    <AddIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl className={classes.fullWidth}>
                    <TextField
                        type="text"
                        margin="dense"
                        className="kt-width-full"
                        name={`liquid_url_${index}`}
                        label={intl.formatMessage({
                            id: "EVENT.LANG.LIQUID_URL"
                        })}
                        variant="outlined"
                        onChange={handleChange}
                        value={room || room.liquid_url !== null ? room.liquid_url : ""}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl className={classes.fullWidth}>
                    <TextField
                        type="text"
                        margin="dense"
                        className="kt-width-full"
                        name={`wss_url_${index}`}
                        label={intl.formatMessage({ id: "EVENT.LANG.WSS_URL" })}
                        variant="outlined"
                        onChange={handleChange}
                        value={room || room.wss_url !== null ? room.wss_url : ""}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

function EventDlg(props) {
    const {
        onClose,
        open,
        eventId,
        eventValue,
        languages,
        admins,
        emitters,
        trans,
        dispatch
    } = props;
    const eventAvatar = useRef(null);
    const eventBg = useRef(null);
    const [event, setEvent] = useState({});
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        if (open && !eventAvatar.current && document.getElementById("kt_event_avatar")) {
            eventAvatar.current = new KTAvatar("kt_event_avatar");
        }

        if (open && !eventBg.current && document.getElementById("kt_event_bg")) {
            eventBg.current = new KTAvatar("kt_event_bg");
        }

        return () => {
            eventAvatar.current = null;
            eventBg.current = null;
        };
    });

    const classes = useDlgStyles();

    useEffect(() => {
        if (eventId && eventId !== -1) {
            let eventValue1 = eventValue;
            if (eventValue1.rooms && eventValue1.rooms.length > 0) {
                const event_rooms = JSON.parse(JSON.stringify(eventValue1.rooms));
                setRooms(event_rooms);

                Object.keys(eventValue1).forEach(function(k) {
                    if (k.startsWith("interpreter_id")) {
                        delete eventValue1[k];
                    }
                    if (k.startsWith("output_lang_id")) {
                        delete eventValue1[k];
                    }
                    if (k.startsWith("liquid_url")) {
                        delete eventValue1[k];
                    }
                    if (k.startsWith("wss_url")) {
                        delete eventValue1[k];
                    }
                });

                event_rooms.forEach((room, index) => {
                    eventValue1[`output_lang_id_${index}`] = room.output_lang_id;
                    eventValue1[`interpreter_id_${index}`] = room.interpreter_id;
                    eventValue1[`liquid_url_${index}`] = room.liquid_url;
                    eventValue1[`wss_url_${index}`] = room.wss_url;
                });
            }
            setEvent(eventValue1);
        } else {
            setRooms([
                {
                    interpreter_id: "",
                    output_lang_id: "",
                    liquid_url: "",
                    wss_url: ""
                }
            ]);
        }
    }, [eventId, eventValue]);

    const handleClose = () => {
        setEvent({});
        setRooms([]);
        onClose();
    };

    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    // Submit Button Effects

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    // Handle Change Event Values

    function convertApiToLocalTime(start_datetime) {
        if (start_datetime) {
            const time = new Date(start_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return start_datetime.split(/[\sT]+/)[0] + "T" + time;
        } else {
            return "";
        }
    }

    const handleRemove = index => {
        if (index !== -1) {
            if (rooms.length !== 1) {
                setRooms(prev => {
                    const newRooms = prev.filter((row, i) => {
                        return index !== i;
                    });
                    return newRooms;
                });
            }
        }
        // setRooms([...rooms, { output_lang_id: null, interpreter_id: null }])
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-event-dialog-title">
                {intl.formatMessage({ id: "EVENTDLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={event}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.input_lang_id) {
                            errors.input_lang_id = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.title) {
                            errors.title = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.detail) {
                            errors.detail = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setStatus, setSubmitting }) => {
                        if (!values.event_type) {
                            values.event_type = 1;
                        }
                        enableLoading();

                        if (!_.isNil(values.pic)) {
                            let formData = new FormData();
                            formData.append("pic", values.pic);
                            await upload_event_pic(formData)
                                .then(response => {
                                    values.pic = response.data.file_name;
                                })
                                .catch(error => {});
                        }

                        if (!_.isNil(values.bg_pic)) {
                            let formData = new FormData();
                            formData.append("bg_pic", values.bg_pic);
                            await upload_event_pic(formData)
                                .then(response => {
                                    values.bg_pic = response.data.file_name;
                                })
                                .catch(error => {});
                        }

                        setTimeout(() => {
                            if (eventId !== -1) {
                                if (values.emitter_id !== 0 && values.event_type === 1) {
                                    const emitter_id = values.emitter_id;
                                    values.emitter_name = emitters.find(
                                        emitter => emitter.id === emitter_id
                                    ).name;
                                } else {
                                    values.emitter_name = 0;
                                }

                                dispatch(eventsStore.actions.updateEvents(values));
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(eventsStore.actions.addEvent(values));
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="event_type"
                                    name="event_type"
                                    value={values.event_type ? parseInt(values.event_type) : 1}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.CONFERENCE"
                                        })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.MEETING"
                                        })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.WEBINAR"
                                        })}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    {intl.formatMessage({
                                        id: "USER.FIELD.AVATAR"
                                    })}
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div
                                        className="kt-avatar kt-avatar--outline"
                                        id="kt_event_avatar"
                                    >
                                        <div
                                            className="kt-avatar__holder"
                                            style={{
                                                backgroundImage: `url(${
                                                    event.pic
                                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                                        : toAbsoluteUrl(
                                                              "/media/logos/logo-trans.png"
                                                          )
                                                })`
                                            }}
                                        />
                                        <label
                                            className="kt-avatar__upload"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Change avatar"
                                        >
                                            <i className="fa fa-pen"></i>
                                            <input
                                                type="file"
                                                onChange={e => {
                                                    setFieldValue("pic", e.currentTarget.files[0]);
                                                }}
                                                name="pic"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                        </label>
                                        <span
                                            className="kt-avatar__cancel"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Cancel avatar"
                                        >
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    {intl.formatMessage({
                                        id: "USER.FIELD.BACKGROUND"
                                    })}
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div className="kt-avatar kt-avatar--outline" id="kt_event_bg">
                                        <div
                                            className="kt-avatar__holder"
                                            style={{
                                                backgroundImage: `url(${
                                                    event.pic
                                                        ? process.env.REACT_APP_FILE_URL +
                                                          event.bg_pic
                                                        : toAbsoluteUrl("/media/bg/bg-1.jpg")
                                                })`
                                            }}
                                        />
                                        <label
                                            className="kt-avatar__upload"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Change avatar"
                                        >
                                            <i className="fa fa-pen"></i>
                                            <input
                                                type="file"
                                                onChange={e => {
                                                    setFieldValue(
                                                        "bg_pic",
                                                        e.currentTarget.files[0]
                                                    );
                                                }}
                                                name="bg_pic"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                        </label>
                                        <span
                                            className="kt-avatar__cancel"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Cancel avatar"
                                        >
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "EVENTS.TABLE_HEADER.TITLE"
                                    })}
                                    className="kt-width-full"
                                    name="title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title ? values.title : ""}
                                    helperText={touched.title && errors.title}
                                    error={Boolean(touched.title && errors.title)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "EVENTS.TABLE_HEADER.DETAIL"
                                    })}
                                    variant="outlined"
                                    multiline
                                    rowsMax="4"
                                    className="kt-width-full"
                                    name="detail"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.detail ? values.detail : ""}
                                    helperText={touched.detail && errors.detail}
                                    error={Boolean(touched.detail && errors.detail)}
                                />
                            </div>

                            <div className="form-group">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="input-lang-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.INPUT_LANG"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="input-lang-checkbox-label"
                                                id="in-lang-checkbox"
                                                value={
                                                    values.input_lang_id ? values.input_lang_id : ""
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name="input_lang_id"
                                            >
                                                {languages.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.lang_label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="emitter-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENTS.TABLE_HEADER.EMITTER"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="emitter-checkbox-label"
                                                id="emitter-checkbox"
                                                value={values.emitter_id ? values.emitter_id : ""}
                                                onChange={handleChange}
                                                name="emitter_id"
                                                disabled={
                                                    values.event_type
                                                        ? parseInt(values.event_type) !== 1
                                                        : false
                                                }
                                            >
                                                {emitters.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.fullWidth}>
                                            <TextField
                                                type="text"
                                                margin="dense"
                                                className="kt-width-full"
                                                name="liquid_url"
                                                label={intl.formatMessage({
                                                    id: "EVENT.LANG.LIQUID_URL"
                                                })}
                                                variant="outlined"
                                                onChange={handleChange}
                                                value={values.liquid_url}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.fullWidth}>
                                            <TextField
                                                type="text"
                                                margin="dense"
                                                className="kt-width-full"
                                                name="wss_url"
                                                label={intl.formatMessage({
                                                    id: "EVENT.LANG.WSS_URL"
                                                })}
                                                variant="outlined"
                                                onChange={handleChange}
                                                value={values.wss_url}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="form-group">
                                {rooms.length > 0 &&
                                    rooms.map((room, index) => (
                                        <Room
                                            key={index}
                                            index={index}
                                            room={room}
                                            intl={intl}
                                            languages={languages}
                                            trans={trans}
                                            values={values}
                                            handleRemove={handleRemove}
                                            handleChange={handleChange}
                                            pstate={{ rooms, setRooms }}
                                        />
                                    ))}
                            </div>

                            <div className="form-group">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="start_datetime-local"
                                            label={intl.formatMessage({
                                                id: "EVENTS.TABLE_HEADER.START_DATE"
                                            })}
                                            type="datetime-local"
                                            className={classes.textField}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="start_datetime"
                                            value={convertApiToLocalTime(values.start_datetime)}
                                            helperText={
                                                touched.start_datetime && errors.start_datetime
                                            }
                                            error={Boolean(
                                                touched.start_datetime && errors.start_datetime
                                            )}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="admin-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.ADMIN"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="admin-checkbox-label"
                                                id="admin-checkbox"
                                                value={values.admin_id ? values.admin_id : ""}
                                                onChange={handleChange}
                                                name="admin_id"
                                            >
                                                {admins.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

EventDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    eventId: PropTypes.any
};

export default injectIntl(connect()(EventDlg));
