import React from "react";
import { Grid } from "@material-ui/core";

function MeetContainer(props) {
    const { children } = props;

    const onMouseOver = () => {
        // dispatch(eventStore.actions.showToolbox())
    };

    return (
        <>
            <Grid
                id="meet"
                className={props.className ? props.className : ""}
                onMouseOver={onMouseOver}
            >
                {children}
            </Grid>
        </>
    );
}

export default MeetContainer;
