import { calcWidth, calcSidePlaceHeight } from "./utils/VideoLayoutUtils";
import { isInterpreter } from "./utils/RoomUtils";
import { useEffect } from "react";
import store from "../store/store";
import { UserRole } from "./utils/UserRole";

function useHandleMosaicView(isMosaic, screenMode, participants) {
    useEffect(() => {
        const state = store.getState();
        const room = state.event.room;
        if (!room) {
            calcWidth(isMosaic, 0);
            calcSidePlaceHeight();
            return;
        }
        let _participants = room.getParticipants();
        _participants = _participants.filter(p => {
            if (isInterpreter(_participants, p.getId())) {
                return (
                    state.auth.user &&
                    [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.EVENT_MANAGER].indexOf(
                        state.auth.user.role
                    ) !== -1
                );
            } else {
                return true;
            }
        });
        calcWidth(isMosaic, _participants.length);
        calcSidePlaceHeight();
    }, [isMosaic, screenMode, participants]);
}

export default useHandleMosaicView;
