import React from "react";
import { Button, styled, colors } from "@material-ui/core";

const ToggleButtonItem = styled(Button)(({ theme, selected }) => ({
    backgroundColor: "#1A2027",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: colors.common.white,
    margin: 2,
    whiteSpace: "nowrap",
    borderTopColor: selected ? "red" : "transparent",
    borderTopWidth: 4,
    borderTopStyle: "solid"
}));

function ToggleButton(props) {
    const { selected, onChanged, children, className } = props;

    return (
        <ToggleButtonItem
            selected={selected}
            onClick={() => {
                onChanged(!selected);
            }}
            className={className}
        >
            {children}
        </ToggleButtonItem>
    );
}

export default ToggleButton;
