import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        minWidth: 120
    }
}));

function SelectDeviceDialog(props) {
    const { id, title, label, isOpen, devices, currentValue, onChange, intl } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (isOpen) setOpen(isOpen);
    }, [isOpen]);

    // Handle Cancel
    function handleCancel() {
        onChange(currentValue, false);
        setOpen(false);
    }

    // Handle Okay
    function handleOk(event) {
        onChange(currentValue, false);
        setOpen(false);
    }

    function handleClose(event, reason) {
        console.log(event);
        console.log(reason);
    }

    return (
        <>
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                disablePortal
                style={{ position: "absolute" }}
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={id}>{label}</InputLabel>
                            <Select
                                onChange={e => onChange(e.target.value)}
                                inputProps={{
                                    name: id,
                                    id: id
                                }}
                                value={
                                    currentValue === "" && devices && devices.length > 0
                                        ? devices[0].deviceId
                                        : currentValue
                                }
                            >
                                {devices &&
                                    devices.map(device => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        {intl.formatMessage({ id: "EVENT.ACTION.CANCEL" })}
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        {intl.formatMessage({ id: "EVENT.ACTION.OK" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

SelectDeviceDialog.propTypes = {
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    currentValue: PropTypes.string.isRequired
};

export default injectIntl(SelectDeviceDialog);
