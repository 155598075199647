import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { meetingVideo as useStyles } from "./CommonStyles";
import RemoteVideo from "../../../../components/RemoteVideo";
import SelectDeviceDialog from "../../../../components/SelectDeviceDialog";
import { calcSidePlaceHeight } from "./VideoLayoutUtils";
import useHandleMosaicView from "./useHandleMosaicView";
import * as eventStore from "../../../../store/ducks/event.duck";
import * as messageStore from "../../../../store/ducks/messages.duck";

import Chat from "../../../../components/Chat1";
import { UserRole } from "../../../../components/utils/UserRole";
import { EventType } from "../../../../components/utils/EventType";
import MeetContainer from "../../../../components/MeetContainer";
import VideoTrack from "../../../../components/VideoTrack";
import { jitsiMeeting } from "../../../../components/JitsiMeeting";
// import { audioMixer } from "../../../../components/AudioMixer";
import AudioPlace from "../../../../components/AudioPlace";

function MeetingVideo(props) {
    const {
        event,
        isJoining,
        isEndMeeting,
        isMosaic,
        screenMode,
        // streamMode, isDirect,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        focusedId,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        openChatBox,
        setToParticipant,
        isChatBoxOpen,
        user,
        authToken,
        participants,
        setParticipants,
        messageList,
        getMessages,
        // setGroupTranslator,
        audioLevels,
        localTracks,
        isSubTitle,
        audioTracks,
        intl
    } = props;
    const classes = useStyles(props);

    // const [cameraSetting, setCameraSetting] = React.useState("");
    // const [audioOutputDevices, setAudioOutputDevices] = React.useState([]);
    // const [audioInputDevices, setAudioInputDevices] = React.useState([]);
    // const [audioInputSetting, setAudioInputSetting] = React.useState("");
    // const [audioOutputSetting, setAudioOutputSetting] = React.useState("");
    // const [receiveMessageObject, setReceiveMessageObject] = React.useState("");
    // const [focusedId, setFocusedId] = React.useState(null);

    // const audioCtx = React.useRef(null);
    // const gainNode = React.useRef(null);
    // const bassFilter = React.useRef(null);
    // const trebleFilter = React.useRef(null);
    // const streamsToMix = React.useRef([]);
    // const channelMerger = React.useRef(null);
    // const channelSplitter = React.useRef(null);

    // const castAudioCtx = React.useRef(null);
    // const castSource = React.useRef(null);
    // const webCast = React.useRef(null);

    // const isAddFloor = React.useRef(false);
    // const audioMixer = React.useRef(null);

    const startJitsiMeeting = useCallback(() => {
        if (!event) {
            return;
        }
        // Move down the toolbox
        // dispatch(eventStore.actions.hideToolbox())

        const appId = process.env.REACT_APP_APP_ID;

        if (!jitsiMeeting.connection) {
            jitsiMeeting.connect(appId, authToken, event);
        }
    }, [authToken, event]);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        } else {
            startJitsiMeeting();
        }
    }, [isEndMeeting, startJitsiMeeting]);

    useEffect(() => {
        // castAudioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
        // webCast.current = castAudioCtx.current.createWebcastSource(4096, 2);

        calcSidePlaceHeight();

        getMessages();

        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }

            // if (webCast.current) {
            //     webCast.current.close(function () {
            //         console.log('left')
            //     })
            // }
        };
    }, [getMessages]);

    useEffect(() => {
        if (isJoining === 1) {
            // handleRoomJoined();
        }
    }, [isJoining]);

    useHandleMosaicView(isMosaic, screenMode, participants, isSubTitle, isChatBoxOpen);

    function handleClickSidePlace(pId) {
        if (pId === "selfPlace") {
            jitsiMeeting.setFocusedId(jitsiMeeting.room.myUserId());
        } else {
            jitsiMeeting.setReceiverConstraints(pId);

            console.log("🚀 ~ handleClickSidePlace ~ pId:", pId);
            jitsiMeeting.setFocusedId(pId);
        }

        if (jitsiMeeting.room) {
            setParticipants(jitsiMeeting.room.getParticipants());
        }
    }

    function renderRemoteParticipant(p) {
        const pId = p.getId();
        const displayName = p.getDisplayName();

        const videoTrack = jitsiMeeting.remoteTracks[pId].find(
            t => t.getType() === "video" && !t.disposed
        );

        let videoElement = "";
        let placeClass = classes.participantContainer;
        if (event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) {
            if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                placeClass += ` interpreterPlace ${classes.hiddenParticipant}`;
            } else if (parseInt(p.getProperty("role")) > UserRole.EMITTER) {
                placeClass += ` ${classes.hiddenParticipant}`;
            }
        } else {
            placeClass +=
                parseInt(p.getProperty("role")) === UserRole.INTERPRETER
                    ? ` interpreterPlace ${classes.hiddenParticipant}`
                    : " ";
        }

        let _isMuted = true;
        _isMuted = !!jitsiMeeting.remoteTracks[pId].find(t => t.getType() === "audio" && t.muted);

        if (parseInt(p.getProperty("role")) !== UserRole.OBSERVER) {
            if (videoTrack) {
                videoElement = (
                    <VideoTrack
                        id={`${pId}video`}
                        className={classes.video}
                        muted={true}
                        videoTrack={{ jitsiTrack: videoTrack }}
                    />
                );

                jitsiMeeting.setReceiverConstraints(pId);
            }
        }

        const p_output = p.getProperty("output");
        const is_non = p_output ? p_output.includes("non-") : false;

        return (
            <div
                key={pId}
                id={`${pId}Place`}
                className={placeClass}
                onClick={() => handleClickSidePlace(pId)}
            >
                <RemoteVideo
                    id={pId}
                    displayName={displayName}
                    baseClasses={classes}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    messageList={messageList}
                    lang={intl.locale}
                    sendPreMsg={sendPreMsg}
                    isNotify={true}
                    isMicOn={!is_non}
                    isMuted={_isMuted}
                    audioLevels={audioLevels}
                    isMosaic={isMosaic}
                >
                    {videoElement}
                </RemoteVideo>
            </div>
        );
    }

    function sendPreMsg(participantId, msg) {
        jitsiMeeting.room.sendCommandOnce("moderator", {
            value: participantId,
            attributes: {
                actionType: "notify",
                content: "msg",
                ...msg
            }
        });
    }

    function unload() {
        jitsiMeeting.unload();
    }

    function _getBigVideoTrack() {
        let newBigVideoTrack = null;
        if (event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) {
            const emitter = participants.find(
                participant => parseInt(participant.getProperty("role")) === UserRole.EMITTER
            );
            if (emitter) {
                const tracks = jitsiMeeting.remoteTracks[emitter.getId()];
                newBigVideoTrack = tracks.find(t => t.getType() === "video" && !t.disposed);
            }
        } else {
            newBigVideoTrack = localTracks.find(t => t.getType() === "video");
            if (!jitsiMeeting.room || !focusedId) return newBigVideoTrack;

            if (focusedId === jitsiMeeting.room.myUserId()) {
                newBigVideoTrack = localTracks.find(t => t.getType() === "video" && !t.disposed);
            } else {
                const participant = jitsiMeeting.room.getParticipantById(focusedId);
                if (!participant) return null;

                if (participant.getProperty("role") !== UserRole.OBSERVER) {
                }

                if (participant.getProperty("role") === UserRole.INTERPRETER) {
                }

                const tracks = participant.getTracks();
                if (tracks.find(t => t.getType() === "video")) {
                    newBigVideoTrack = tracks.find(t => t.getType() === "video" && !t.disposed);
                } else {
                    newBigVideoTrack = null;
                }
            }
        }

        return newBigVideoTrack;
    }

    function handleHoverEvent() {}

    function handleLeaveEvent() {}

    /**
     * Handle CameraSetting Change
     * @param {String} newCameraInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            jitsiMeeting.room.sendTextMessage(message);
        } else {
            jitsiMeeting.room.sendPrivateTextMessage(id, message);
        }
    }

    function validUsers(p) {
        const role = parseInt(p.getProperty("role"));
        if (role > 0 && role <= UserRole.USER) {
            return p;
        }
    }

    return (
        <>
            <MeetContainer className={classes.meetContainer}>
                <Grid item xs={12} style={{ height: "100%" }}>
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div id="avatar" className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                        : toAbsoluteUrl("/media/logos/logo-trans.png")
                                }
                                alt={event.title}
                            />
                        </div>

                        <div id="chatContainer" className={classes.chatContainer}>
                            <Chat
                                open={isChatBoxOpen}
                                setIsChatDlg={openChatBox}
                                sendTextMessage={sendTextMessage}
                                participants={participants}
                            />
                        </div>

                        <div id="pWrapper" style={{ position: "relative", flexGrow: 1 }}>
                            <VideoTrack
                                id="bigVideo"
                                className={classes.bigVideo}
                                muted={true}
                                videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                            />

                            <div
                                className={isMosaic ? classes.mosaicOverlay : classes.hideSelfVideo}
                            />

                            <div
                                id="sidePlace"
                                className={
                                    isMosaic ? classes.mosaicRemoteContainer : classes.sidePlace
                                }
                            >
                                <div className="sideWrapper">
                                    <div id="remotePlace" className={classes.remotePlace}>
                                        <div
                                            id="selfPlace"
                                            className={`${classes.participantContainer} ${
                                                jitsiMeeting.room &&
                                                focusedId === jitsiMeeting.room.myUserId()
                                                    ? classes.participantContainerFocused
                                                    : ""
                                            }`}
                                            onClick={() => handleClickSidePlace("selfPlace")}
                                        >
                                            <div className={classes.videoContainer}>
                                                <VideoTrack
                                                    id="selfVideo"
                                                    className={classes.video}
                                                    muted={true}
                                                    videoTrack={{
                                                        jitsiTrack: localTracks.find(
                                                            track => track.getType() === "video"
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="displayName">
                                                {user.name}(
                                                {intl.formatMessage({
                                                    id: "CHAT.ME"
                                                })}
                                                )
                                            </div>
                                        </div>
                                        {participants
                                            .filter(p => validUsers(p))
                                            .map(p => renderRemoteParticipant(p))}
                                    </div>
                                </div>
                            </div>
                            {isSubTitle && (
                                <div id="subTitleWrapper" className={classes.subTitleWrapper}>
                                    <div id="subTitle" className={classes.subTitle}>
                                        {event.subtitle && event.subtitle !== "" && (
                                            <iframe
                                                title={event.title}
                                                src={event.subtitle}
                                                className={classes.subTitleIframe}
                                            ></iframe>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {Object.keys(audioTracks).map(key => (
                            <AudioPlace
                                id={`${key}audio`}
                                key={key}
                                autoPlay={true}
                                audioTrack={{
                                    jitsiTrack: audioTracks[key].audioTrack
                                }}
                                participantId={key}
                                _muted={audioTracks[key].muted}
                                _volume={audioTracks[key].volume ? audioTracks[key].volume : 0}
                            />
                        ))}

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />
                    </div>
                </Grid>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        room: state.event.room,
        startStatId: state.event.startStatId,
        isJoining: state.event.isJoining,
        isVideoOn: state.event.isVideoOn,
        isMicOn: state.event.isMicOn,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        isMosaic: state.event.isMosaic,
        screenMode: state.event.screenMode,
        participants: state.event.participants,
        messageList: state.messages.messageList,
        audioLevels: state.event.audioLevels,
        localTracks: state.event.localTracks,
        focusedId: state.event.focusedId,
        isSubTitle: state.event.isSubTitle,
        audioTracks: state.event.audioTracks
    };
};

const mapDispatchToProps = dispatch => ({
    endMeetingSuccess: () => dispatch(eventStore.actions.endMeetingSuccess()),
    // setConference: room => dispatch(eventStore.actions.setConference(room)),
    // joinedConference: () => dispatch(eventStore.actions.joinedConference()),
    updateMessage: () => dispatch(eventStore.actions.updateMessage()),
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    showNotification: (type, content) =>
        dispatch(eventStore.actions.showNotification(type, content)),
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    changeMessageStatus: isNewMsg => dispatch(eventStore.actions.changeMessageStatus(isNewMsg)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setParticipants: participants => dispatch(eventStore.actions.setParticipants(participants)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant)),
    getMessages: () => dispatch(messageStore.actions.getMessages()),
    setAudioLevelChanged: userAudioLevel =>
        dispatch(eventStore.actions.setAudioLevelChanged(userAudioLevel)),
    setGroupTranslator: groupTranslator =>
        dispatch(eventStore.actions.setGroupTranslator(groupTranslator))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeetingVideo));
