import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Button,
    Icon
} from "@material-ui/core";

import {
    EnhancedTableHead,
    stableSort,
    getSorting,
    searchArray
} from "../../../../components/EnhancedTable";
import * as eventsStore from "../../../../store/ducks/customers/index.duck";
import { toAbsoluteUrl } from "../../../../../_metronic";
import Container from "../../../../partials/content/Container";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    button: {
        // margin: theme.spacing(1)
        padding: 0
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
}));

function EventList(props) {
    const { eventList, getEvents, intl } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchKey, setSearchKey] = React.useState("");

    const headRows = [
        {
            id: "title",
            numeric: false,
            disablePadding: false,
            className: "",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.TITLE" })
        },
        {
            id: "detail",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.DETAIL" })
        },
        {
            id: "start_datetime",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.START_DATE" })
        },
        {
            id: "input_lang",
            numeric: false,
            disablePadding: false,
            className: "pr-0",
            label: intl.formatMessage({ id: "EVENT.FIELD.INPUT_LANG" })
        },
        {
            id: "output_lang",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell pr-0",
            label: intl.formatMessage({ id: "EVENT.FIELD.OUTPUT_LANG" })
        }
    ];

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    // Handle Tables Events
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, eventList ? eventList.length : 0 - page * rowsPerPage);
    const searchable = ["title", "detail", "start_datetime"];

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = eventList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleEdit(event, id) {
        props.history.push(`events/${id}`);
    }

    function convertApiToLocalTime(start_datetime) {
        if (start_datetime) {
            const time = new Date(start_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return start_datetime.split(/[\sT]+/)[0] + " " + time;
        } else {
            return "";
        }
    }

    const onHandleChange = e => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Container
                        beforeCodeTitle={intl.formatMessage({
                            id: "EVENTS.CONTAINER.TITLE"
                        })}
                        handleSearch={onHandleChange}
                    >
                        <Paper className={classes.paper}>
                            {/* <EnhancedTableToolbar numSelected={selected.length} handleAdd={handleAdd} /> */}
                            <div className={classes.tableWrapper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size="medium"
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={eventList ? eventList.length : 0}
                                        headRows={headRows}
                                        isCheckBox={false}
                                    />
                                    <TableBody>
                                        {eventList &&
                                            stableSort(
                                                searchArray(eventList, searchKey, searchable),
                                                getSorting(order, orderBy)
                                            )
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.id);

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell className="pr-0">
                                                                <div className="kt-user-card-v2 kt-user-card-v2--uncircle">
                                                                    <div className="kt-user-card-v2__pic">
                                                                        <img
                                                                            src={
                                                                                row.pic
                                                                                    ? process.env
                                                                                          .REACT_APP_FILE_URL +
                                                                                      row.pic
                                                                                    : toAbsoluteUrl(
                                                                                          "/media/logos/logo-trans.png"
                                                                                      )
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="">
                                                                        {row.title}
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 d-block d-sm-none">
                                                                    {row.detail}
                                                                </div>
                                                                <div className="mt-2 d-block d-sm-none">
                                                                    {convertApiToLocalTime(
                                                                        row.start_datetime
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="d-none d-sm-table-cell px-0">
                                                                {row.detail}
                                                            </TableCell>
                                                            <TableCell className="d-none d-sm-table-cell px-0">
                                                                {convertApiToLocalTime(
                                                                    row.start_datetime
                                                                )}
                                                            </TableCell>
                                                            <TableCell className="pr-0">
                                                                {
                                                                    row.event_rooms[0].room
                                                                        .input_lang.lang_label
                                                                }

                                                                <div className="mt-2 d-block d-sm-none">
                                                                    {[
                                                                        ...new Set(
                                                                            row.event_rooms.reduce(
                                                                                (
                                                                                    accumulator,
                                                                                    event_room
                                                                                ) => {
                                                                                    if (
                                                                                        event_room.interpreter_id !==
                                                                                            0 &&
                                                                                        event_room
                                                                                            .room
                                                                                            .output_lang
                                                                                    ) {
                                                                                        accumulator.push(
                                                                                            event_room
                                                                                                .room
                                                                                                .output_lang
                                                                                                .lang_label
                                                                                        );
                                                                                    }
                                                                                    return accumulator;
                                                                                },
                                                                                []
                                                                            )
                                                                        )
                                                                    ].join(", ")}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="d-none d-sm-table-cell">
                                                                {[
                                                                    ...new Set(
                                                                        row.event_rooms.reduce(
                                                                            (
                                                                                accumulator,
                                                                                event_room
                                                                            ) => {
                                                                                if (
                                                                                    event_room.interpreter_id !==
                                                                                        0 &&
                                                                                    event_room.room
                                                                                        .output_lang
                                                                                ) {
                                                                                    accumulator.push(
                                                                                        event_room
                                                                                            .room
                                                                                            .output_lang
                                                                                            .lang_label
                                                                                    );
                                                                                }
                                                                                return accumulator;
                                                                            },
                                                                            []
                                                                        )
                                                                    )
                                                                ].join(", ")}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    className={classes.button}
                                                                    onClick={event =>
                                                                        handleEdit(event, row.id)
                                                                    }
                                                                >
                                                                    {intl.formatMessage({
                                                                        id:
                                                                            "EVENT.ACTION.JOIN_MEETING"
                                                                    })}
                                                                    <Icon
                                                                        className={
                                                                            classes.rightIcon
                                                                        }
                                                                    >
                                                                        phone
                                                                    </Icon>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 49 * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={8} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={
                                    searchArray(eventList, searchKey, searchable)
                                        ? searchArray(eventList, searchKey, searchable).length
                                        : 0
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Container>
                </div>
            </div>
        </>
    );
}

EventList.propTypes = {
    eventList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired
            // event_id: PropTypes.number.isRequired
        }).isRequired
    ).isRequired
};

const mapStateToProps = state => {
    return {
        eventList: state.customerReducer.eventList
    };
};

const mapDispatchToProps = dispatch => ({
    getEvents: () => dispatch(eventsStore.actions.getCustomerEvents())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventList));
