import React, { useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";

import * as messagesStore from "../../../../store/ducks/messages.duck";

// const useStyles = makeStyles(theme => ({
//     formControl: {
//         width: '100%',
//     },
//     flag: {
//         width: 48,
//         // height: 18,
//         marginRight: theme.spacing(1),
//         borderRadius: 4
//     }
// }))
function MessageDlg(props) {
    const { onClose, open, msgId, msgValue, dispatch, intl } = props;

    // const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "MESSAGE.DETAIL.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={_.isEmpty(msgValue) ? { msg_en: "", msg_es: "" } : msgValue}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.msg_en && !values.msg_es) {
                            errors.msg_label = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (msgId !== -1) {
                                dispatch(
                                    messagesStore.actions.updateMessage(
                                        Object.assign(msgValue, values)
                                    )
                                );
                                disableLoading();
                                handleClose();
                            } else {
                                dispatch(messagesStore.actions.addMessage(values));
                                disableLoading();
                                handleClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label="English"
                                    className="kt-width-full"
                                    name="msg_en"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.msg_en}
                                    helperText={touched.msg_en && errors.msg_en}
                                    error={Boolean(touched.msg_en && errors.msg_en)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label="Spanish"
                                    className="kt-width-full"
                                    name="msg_es"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.msg_es}
                                    helperText={touched.msg_es && errors.msg_es}
                                    error={Boolean(touched.msg_es && errors.msg_es)}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

MessageDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    msgId: PropTypes.any
};

export default injectIntl(connect()(MessageDlg));
