import { put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import { compare } from "../../components/utils/RoomUtils";
import { UserRole } from "../../components/utils/UserRole";
import { post_stat } from "../../crud/customers/index.crud";

export const actionTypes = {
    SET_CONFERENCE: "SET_CONFERENCE",
    CONFERENCE_JOINED: "CONFERENCE_JOINED",
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
    HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
    CHANGE_MIC_ON: "CHANGE_MIC_ON",
    CHANGE_VIDEO_ON: "CHANGE_VIDEO_ON",
    SET_CAMERA_DEVICES: "SET_CAMERA_DEVICES",
    SET_AUDIO_OUTPUT_DEVICES: "SET_AUDIO_OUTPUT_DEVICES",
    SET_AUDIO_INPUT_DEVICES: "SET_AUDIO_INPUT_DEVICES",
    OPEN_CHATBOX: "OPEN_CHATBOX",
    CHANGE_MESSAGE_STATUS: "CHANGE_MESSAGE_STATUS",
    START_MEETING: "START_MEETING",
    END_MEETING: "END_MEETING",
    END_MEETING_SUCCESS: "END_MEETING_SUCCESS",
    UPDATE_ROOM_LIST: "UPDATE_ROOM_LIST",
    UPDATE_ROOM_LIST_SUCCESS: "UPDATE_ROOM_LIST_SUCCESS",
    ADD_STAT: "ADD_STAT",
    ADD_STAT_SUCCESS: "ADD_STAT_SUCCESS",
    UPDATE_MESSAGE: "UPDATE_MESSAGE",
    UPDATE_MESSAGE_SUCCESS: "UPDATE_MESSAGE_SUCCESS",
    UPDATE_IS_MOSAIC: "UPDATE_IS_MOSAIC",
    UPDATE_SCREEN_MODE: "UPDATE_SCREEN_MODE",
    SET_AUDIO_OUTPUT_SETTING: "SET_AUDIO_OUTPUT_SETTING",
    SET_AUDIO_INPUT_SETTING: "SET_AUDIO_INPUT_SETTING",
    SET_CAMERA_SETTING: "SET_CAMERA_SETTING",
    SET_BG_SETTING: "SET_BG_SETTING",
    SET_AUDIO_INPUT_SETTING_DLG: "SET_AUDIO_INPUT_SETTING_DLG",
    SET_AUDIO_OUTPUT_SETTING_DLG: "SET_AUDIO_OUTPUT_SETTING_DLG",
    SET_VIDEO_SETTING_DLG: "SET_VIDEO_SETTING_DLG",
    SET_BG_SETTING_DLG: "SET_BG_SETTING_DLG",
    SET_IS_RAISE: "SET_IS_RAISE",
    SET_IS_CAMERA: "SET_IS_CAMERA",
    SET_DISABLE_VIDEO: "SET_DISABLE_VIDEO",
    SET_DISABLE_MIC: "SET_DISABLE_MIC",
    SET_SHARE_OTHER_CAMERA: "SET_SHARE_OTHER_CAMERA",
    SET_PARTICIPANTS: "SET_PARTICIPANTS",
    SET_TO_PARTICIPANT: "SET_TO_PARTICIPANT",
    TRACK_AUDIO_LEVEL_CHANGED: "TRACK_AUDIO_LEVEL_CHANGED",
    SET_IS_OSCILLOMETER: "SET_IS_OSCILLOMETER",
    SET_IS_CHECK_AUDIO: "SET_IS_CHECK_AUDIO",
    SET_IS_CHECK_MIC: "SET_IS_CHECK_MIC",
    SET_IS_GROUP_TRANSLATOR: "SET_IS_GROUP_TRANSLATOR",
    SET_GROUP_TRANSLATOR: "SET_GROUP_TRANSLATOR",
    SET_TOOLBOX_TIMEOUT: "SET_TOOLBOX_TIMEOUT",
    CLEAR_TOOLBOX_TIMEOUT: "CLEAR_TOOLBOX_TIMEOUT",
    SHOW_TOOLBOX: "SHOW_TOOLBOX",
    HIDE_TOOLBOX: "HIDE_TOOLBOX",
    UPDATE_LOCAL_TRACK: "UPDATE_LOCAL_TRACK",
    SET_FOCUSED_ID: "SET_FOCUSED_ID",
    SET_IS_WITH_FLOOR: "SET_IS_WITH_FLOOR",
    CHANGE_MUTE: "CHANGE_MUTE",
    SET_IS_SUBTITLE: "SET_IS_SUBTITLE",
    CHANGE_INPUT_ROOMNAME: "CHANGE_INPUT_ROOMNAME",
    CHANGE_OUTPUT_ROOMNAME: "CHANGE_OUTPUT_ROOMNAME",
    CHANGE_VOLUME: "CHANGE_VOLUME",
    CHANGE_TREBLE: "CHANGE_TREBLE",
    CHANGE_BASS: "CHANGE_BASS",
    UPDATE_AUDIO_TRACKS: "UPDATE_AUDIO_TRACKS",
    SET_HAND_OVER_READY: "SET_HAND_OVER_READY"
};

export const actions = {
    startMeeting: () => ({ type: actionTypes.START_MEETING }),
    endMeeting: () => ({ type: actionTypes.END_MEETING }),
    endMeetingSuccess: () => ({ type: actionTypes.END_MEETING_SUCCESS }),
    setConference: room => ({ type: actionTypes.SET_CONFERENCE, room }),
    joinedConference: () => ({ type: actionTypes.CONFERENCE_JOINED }),
    updateRoomList: roomList => ({
        type: actionTypes.UPDATE_ROOM_LIST,
        roomList: roomList
    }),
    updateRoomListSuccess: () => ({
        type: actionTypes.UPDATE_ROOM_LIST_SUCCESS
    }),
    addStat: data => ({ type: actionTypes.ADD_STAT, data: data }),
    addStatSuccess: startStatId => ({
        type: actionTypes.ADD_STAT_SUCCESS,
        startStatId: startStatId
    }),
    updateMessage: message => ({ type: actionTypes.UPDATE_MESSAGE, message }),
    updateMessageSuccess: () => ({ type: actionTypes.UPDATE_MESSAGE_SUCCESS }),
    updateIsMosaic: isMosaic => ({
        type: actionTypes.UPDATE_IS_MOSAIC,
        isMosaic: isMosaic
    }),
    updateScreenMode: mode => ({
        type: actionTypes.UPDATE_SCREEN_MODE,
        mode: mode
    }),
    setCameraDevices: cameraDevices => ({
        type: actionTypes.SET_CAMERA_DEVICES,
        cameraDevices
    }),
    setAudioOutputDevices: audioOutputDevices => ({
        type: actionTypes.SET_AUDIO_OUTPUT_DEVICES,
        audioOutputDevices
    }),
    setAudioInputDevices: audioInputDevices => ({
        type: actionTypes.SET_AUDIO_INPUT_DEVICES,
        audioInputDevices
    }),
    setBg: cameraDevices => ({
        type: actionTypes.SET_CAMERA_DEVICES,
        cameraDevices
    }),
    changeMicOn: isMicOn => ({ type: actionTypes.CHANGE_MIC_ON, isMicOn }),
    changeMute: isMuted => ({ type: actionTypes.CHANGE_MUTE, isMuted }),
    changeVideoOn: isVideoOn => ({
        type: actionTypes.CHANGE_VIDEO_ON,
        isVideoOn
    }),
    setDisableVideo: disableVideo => ({
        type: actionTypes.SET_DISABLE_VIDEO,
        disableVideo
    }),
    setDisableMic: disableMic => ({
        type: actionTypes.SET_DISABLE_MIC,
        disableMic
    }),
    openChatBox: isChatBoxOpen => ({
        type: actionTypes.OPEN_CHATBOX,
        isChatBoxOpen
    }),
    changeMessageStatus: isNewMsg => ({
        type: actionTypes.CHANGE_MESSAGE_STATUS,
        isNewMsg
    }),
    showNotification: (type, content) => ({
        type: actionTypes.SHOW_NOTIFICATION,
        content: content,
        msgtype: type
    }),
    hideNotification: () => ({ type: actionTypes.HIDE_NOTIFICATION }),
    setAudioOutputSetting: audioOutputSetting => ({
        type: actionTypes.SET_AUDIO_OUTPUT_SETTING,
        audioOutputSetting
    }),
    setAudioInputSetting: audioInputSetting => ({
        type: actionTypes.SET_AUDIO_INPUT_SETTING,
        audioInputSetting
    }),
    setCameraSetting: cameraSetting => ({
        type: actionTypes.SET_CAMERA_SETTING,
        cameraSetting
    }),
    setBgSetting: background => ({
        type: actionTypes.SET_BG_SETTING,
        background
    }),
    setOpenAudioInputSettingDlg: isOpen => ({
        type: actionTypes.SET_AUDIO_INPUT_SETTING_DLG,
        isOpen
    }),
    setOpenAudioOutputSettingDlg: isOpen => ({
        type: actionTypes.SET_AUDIO_OUTPUT_SETTING_DLG,
        isOpen
    }),
    setOpenVideoSettingDlg: isOpen => ({
        type: actionTypes.SET_VIDEO_SETTING_DLG,
        isOpen
    }),
    setOpenBgSettingDlg: isOpen => ({
        type: actionTypes.SET_BG_SETTING_DLG,
        isOpen
    }),
    setIsRaise: isRaise => ({ type: actionTypes.SET_IS_RAISE, isRaise }),
    setIsCamera: isCamera => ({ type: actionTypes.SET_IS_CAMERA, isCamera }),
    setIsShareOtherCamera: isShareOtherCamera => ({
        type: actionTypes.SET_SHARE_OTHER_CAMERA,
        isShareOtherCamera
    }),
    setParticipants: participants => ({
        type: actionTypes.SET_PARTICIPANTS,
        participants
    }),
    setToParticipant: toParticipant => ({
        type: actionTypes.SET_TO_PARTICIPANT,
        toParticipant
    }),
    setAudioLevelChanged: userAudioLevel => ({
        type: actionTypes.TRACK_AUDIO_LEVEL_CHANGED,
        userAudioLevel
    }),
    setIsOscilloMeter: isOscilloMeter => ({
        type: actionTypes.SET_IS_OSCILLOMETER,
        isOscilloMeter
    }),
    setIsCheckAudio: isCheckAudio => ({
        type: actionTypes.SET_IS_CHECK_AUDIO,
        isCheckAudio
    }),
    setIsCheckMic: isCheckMic => ({
        type: actionTypes.SET_IS_CHECK_MIC,
        isCheckMic
    }),
    setHandOverReady: isHandOverReady => ({
        type: actionTypes.SET_HAND_OVER_READY,
        isHandOverReady
    }),
    setIsGroupTranslator: isGroupTranslator => ({
        type: actionTypes.SET_IS_GROUP_TRANSLATOR,
        isGroupTranslator
    }),
    setGroupTranslator: groupTranslator => ({
        type: actionTypes.SET_GROUP_TRANSLATOR,
        groupTranslator
    }),
    setToolboxTimeout: timeoutID => ({
        type: actionTypes.SET_TOOLBOX_TIMEOUT,
        timeoutID
    }),
    clearToolboxTimeout: timeoutID => ({
        type: actionTypes.CLEAR_TOOLBOX_TIMEOUT,
        timeoutID
    }),
    showToolbox: () => ({ type: actionTypes.SHOW_TOOLBOX }),
    hideToolbox: () => ({ type: actionTypes.HIDE_TOOLBOX }),
    updateLocalTracks: localTracks => ({
        type: actionTypes.UPDATE_LOCAL_TRACK,
        localTracks
    }),
    setFocusedId: focusedId => ({
        type: actionTypes.SET_FOCUSED_ID,
        focusedId
    }),
    setIsWithFloor: isWithFloor => ({
        type: actionTypes.SET_IS_WITH_FLOOR,
        isWithFloor
    }),
    setIsSubTitle: isSubTitle => ({
        type: actionTypes.SET_IS_SUBTITLE,
        isSubTitle
    }),
    changeInputRoomname: inputRoomname => ({
        type: actionTypes.CHANGE_INPUT_ROOMNAME,
        inputRoomname
    }),
    changeOutputRoomname: outputRoomname => ({
        type: actionTypes.CHANGE_OUTPUT_ROOMNAME,
        outputRoomname
    }),
    changeVolume: volume => ({
        type: actionTypes.CHANGE_VOLUME,
        volume
    }),
    changeTreble: treble => ({
        type: actionTypes.CHANGE_TREBLE,
        treble
    }),
    changeBass: bass => ({
        type: actionTypes.CHANGE_BASS,
        bass
    }),
    updateAudioTracks: audioTracks => ({
        type: actionTypes.UPDATE_AUDIO_TRACKS,
        audioTracks
    })
};

const initialState = {
    room: null,
    isStartMeeting: false,
    isEndMeeting: false,
    isRoomListUpdated: false,
    isMessageUpdated: false,
    isMosaic: false,
    startStatId: 0,
    screenMode: 0,
    isNotification: false,
    type: null,
    content: null,
    isMicOn: false, // is Mic On/Off,
    isMuted: false, // false: Unmute, true: Mute
    isVideoOn: false, // is Video On/Off,
    cameraDevices: [],
    isChatBoxOpen: false,
    isNewMsg: false,
    audioInputSetting: "",
    audioOutputSetting: "",
    cameraSetting: "",
    openVideoSettingDlg: false,
    openAudioOutputSettingDlg: false,
    openAudioInputSettingDlg: false,
    openBgSettingDlg: false,
    isRaise: false,
    isCamera: true, // is Camera On/Off,
    isShareOtherCamera: false,
    participants: [],
    isJoining: 0,
    toParticipant: "all",
    audioLevels: [],
    isOscilloMeter: false,
    isCheckAudio: false,
    isCheckMic: false,
    isGroupTranslator: false,
    groupTranslator: [],
    isToolbarShow: true,
    isSidePlaceShow: true,
    timeoutID: undefined,
    localTracks: [],
    focusedId: null,
    audioOutputDevices: [],
    audioInputDevices: [],
    chatMessageList: [],
    isWithFloor: false,
    disableMic: false,
    disableVideo: false,
    isSubTitle: false,
    inputRoomname: "",
    outputRoomname: "",
    volume: 0.5,
    bass: 4,
    treble: 4,
    audioTracks: {},
    isHandOverReady: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_MEETING: {
            return {
                ...initialState,
                audioInputSetting: state.audioInputSetting,
                audioOutputSetting: state.audioOutputSetting,
                cameraSetting: state.cameraSetting,
                cameraDevices: state.cameraDevices,
                audioOutputDevices: state.audioOutputDevices,
                audioInputDevices: state.audioInputDevices,
                inputRoomname: state.inputRoomname,
                isStartMeeting: true,
                isEndMeeting: false
            };
        }
        case actionTypes.END_MEETING: {
            return {
                ...state,
                isEndMeeting: true
            };
        }
        case actionTypes.END_MEETING_SUCCESS: {
            return {
                ...initialState,
                audioInputSetting: state.audioInputSetting,
                audioOutputSetting: state.audioOutputSetting,
                cameraSetting: state.cameraSetting,
                cameraDevices: state.cameraDevices,
                audioOutputDevices: state.audioOutputDevices,
                audioInputDevices: state.audioInputDevices,
                isStartMeeting: false,
                isEndMeeting: true
            };
        }
        case actionTypes.SET_CONFERENCE: {
            return {
                ...state,
                room: action.room,
                isJoining: 1
            };
        }
        case actionTypes.CONFERENCE_JOINED: {
            return {
                ...state,
                isJoining: 2
            };
        }
        case actionTypes.UPDATE_ROOM_LIST: {
            return {
                ...state,
                roomList: action.roomList,
                isRoomListUpdated: true
            };
        }
        case actionTypes.UPDATE_ROOM_LIST_SUCCESS: {
            return {
                ...state,
                isRoomListUpdated: false
            };
        }
        case actionTypes.UPDATE_MESSAGE: {
            const chatMessageList = [...state.chatMessageList];
            chatMessageList.push(action.message);
            return {
                ...state,
                isMessageUpdated: true,
                chatMessageList: chatMessageList
            };
        }
        case actionTypes.UPDATE_MESSAGE_SUCCESS: {
            return {
                ...state,
                isMessageUpdated: false
            };
        }
        case actionTypes.UPDATE_IS_MOSAIC: {
            return {
                ...state,
                isMosaic: action.isMosaic
            };
        }
        case actionTypes.ADD_STAT_SUCCESS: {
            return {
                ...state,
                startStatId: action.startStatId
            };
        }
        case actionTypes.UPDATE_SCREEN_MODE: {
            return {
                ...state,
                screenMode: action.mode
            };
        }
        case actionTypes.SET_CAMERA_DEVICES: {
            return { ...state, cameraDevices: action.cameraDevices };
        }
        case actionTypes.SET_AUDIO_INPUT_DEVICES: {
            return { ...state, audioInputDevices: action.audioInputDevices };
        }
        case actionTypes.SET_AUDIO_OUTPUT_DEVICES: {
            return { ...state, audioOutputDevices: action.audioOutputDevices };
        }
        case actionTypes.SET_AUDIO_INPUT_SETTING: {
            return { ...state, audioInputSetting: action.audioInputSetting };
        }
        case actionTypes.SET_AUDIO_OUTPUT_SETTING: {
            return { ...state, audioOutputSetting: action.audioOutputSetting };
        }
        case actionTypes.SET_CAMERA_SETTING: {
            return { ...state, cameraSetting: action.cameraSetting };
        }
        case actionTypes.SET_BG_SETTING: {
            return { ...state, background: action.background };
        }
        case actionTypes.CHANGE_MIC_ON: {
            return { ...state, isMicOn: action.isMicOn };
        }
        case actionTypes.CHANGE_MUTE: {
            return { ...state, isMuted: action.isMuted };
        }
        case actionTypes.CHANGE_VIDEO_ON: {
            return { ...state, isVideoOn: action.isVideoOn };
        }
        case actionTypes.SET_DISABLE_VIDEO: {
            return { ...state, disableVideo: action.disableVideo };
        }
        case actionTypes.SET_DISABLE_MIC: {
            return { ...state, disableMic: action.disableMic };
        }
        case actionTypes.OPEN_CHATBOX: {
            if (action.isChatBoxOpen) {
                return {
                    ...state,
                    isChatBoxOpen: action.isChatBoxOpen,
                    isNewMsg: false
                };
            }
            return { ...state, isChatBoxOpen: action.isChatBoxOpen };
        }
        case actionTypes.CHANGE_MESSAGE_STATUS: {
            if (state.isChatBoxOpen) {
                return { ...state, isNewMsg: false };
            }
            return { ...state, isNewMsg: action.isNewMsg };
        }
        case actionTypes.SHOW_NOTIFICATION: {
            return {
                ...state,
                isNotification: true,
                type: action.msgtype,
                content: action.content
            };
        }
        case actionTypes.HIDE_NOTIFICATION: {
            return {
                ...state,
                isNotification: false,
                type: null,
                content: null
            };
        }
        case actionTypes.SET_VIDEO_SETTING_DLG: {
            return {
                ...state,
                openVideoSettingDlg: action.isOpen,
                openAudioOutputSettingDlg: false,
                openAudioInputSettingDlg: false,
                openBgSettingDlg: false
            };
        }
        case actionTypes.SET_AUDIO_INPUT_SETTING_DLG: {
            return {
                ...state,
                openVideoSettingDlg: false,
                openAudioOutputSettingDlg: false,
                openAudioInputSettingDlg: action.isOpen,
                openBgSettingDlg: false
            };
        }
        case actionTypes.SET_AUDIO_OUTPUT_SETTING_DLG: {
            return {
                ...state,
                openVideoSettingDlg: false,
                openAudioOutputSettingDlg: action.isOpen,
                openAudioInputSettingDlg: false,
                openBgSettingDlg: false
            };
        }
        case actionTypes.SET_BG_SETTING_DLG: {
            return {
                ...state,
                openVideoSettingDlg: false,
                openAudioOutputSettingDlg: false,
                openAudioInputSettingDlg: false,
                openBgSettingDlg: action.isOpen
            };
        }
        case actionTypes.SET_IS_RAISE: {
            return {
                ...state,
                isRaise: action.isRaise
            };
        }
        case actionTypes.SET_IS_CAMERA: {
            if (!action.isCamera) {
                return {
                    ...state,
                    isCamera: false,
                    isShareOtherCamera: false
                };
            }
            return {
                ...state,
                isCamera: action.isCamera
            };
        }
        case actionTypes.SET_SHARE_OTHER_CAMERA: {
            if (action.isShareOtherCamera) {
                return {
                    ...state,
                    isCamera: true,
                    isShareOtherCamera: true
                };
            }
            return {
                ...state,
                isShareOtherCamera: action.isShareOtherCamera
            };
        }
        case actionTypes.SET_PARTICIPANTS: {
            let newParticipants = action.participants;
            let interpreters = [];
            let otherParticipants = [];
            newParticipants.forEach(p => {
                if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                    interpreters.push(p);
                } else {
                    otherParticipants.push(p);
                }
            });
            interpreters.sort(compare);
            otherParticipants.sort(compare);
            newParticipants = [...interpreters, ...otherParticipants];

            return {
                ...state,
                participants: newParticipants
            };
        }
        case actionTypes.SET_TO_PARTICIPANT: {
            return {
                ...state,
                toParticipant: action.toParticipant
            };
        }
        case actionTypes.TRACK_AUDIO_LEVEL_CHANGED: {
            const audioLevels = [...state.audioLevels];
            const userAudioLevel = action.userAudioLevel;
            const index = audioLevels.findIndex(a => a.userId === userAudioLevel.userId);
            if (index === -1) {
                audioLevels.push(userAudioLevel);
            } else {
                audioLevels[index] = userAudioLevel;
            }
            // audioLevels[userAudioLevel.userId] = userAudioLevel.audioLevel
            return {
                ...state,
                audioLevels: audioLevels
            };
        }
        case actionTypes.SET_IS_OSCILLOMETER: {
            return {
                ...state,
                isOscilloMeter: action.isOscilloMeter
            };
        }
        case actionTypes.SET_IS_GROUP_TRANSLATOR: {
            return {
                ...state,
                isGroupTranslator: action.isGroupTranslator
            };
        }
        case actionTypes.SET_GROUP_TRANSLATOR: {
            if (action.groupTranslator) {
                if (typeof action.groupTranslator === "string") {
                    const oldGroupTranslator = state.groupTranslator.filter(
                        p => p.getId() !== action.groupTranslator
                    );
                    return {
                        ...state,
                        groupTranslator: oldGroupTranslator
                    };
                } else {
                    const groupTranslator = [action.groupTranslator];
                    return {
                        ...state,
                        groupTranslator: [...groupTranslator]
                    };
                }
            } else {
                return {
                    ...state,
                    groupTranslator: []
                };
            }
        }
        case actionTypes.SET_TOOLBOX_TIMEOUT: {
            return {
                ...state,
                timeoutID: action.timeoutID,
                isToolbarShow: true
            };
        }
        case actionTypes.CLEAR_TOOLBOX_TIMEOUT: {
            return {
                ...state,
                timeoutID: undefined,
                isToolbarShow: false
            };
        }
        case actionTypes.UPDATE_LOCAL_TRACK: {
            return {
                ...state,
                localTracks: [...action.localTracks]
            };
        }
        case actionTypes.SET_FOCUSED_ID: {
            return {
                ...state,
                focusedId: action.focusedId
            };
        }
        case actionTypes.SET_IS_WITH_FLOOR: {
            return {
                ...state,
                isWithFloor: action.isWithFloor
            };
        }
        case actionTypes.SET_IS_SUBTITLE: {
            return {
                ...state,
                isSubTitle: action.isSubTitle
            };
        }
        case actionTypes.CHANGE_INPUT_ROOMNAME: {
            return {
                ...state,
                inputRoomname: action.inputRoomname
            };
        }
        case actionTypes.CHANGE_OUTPUT_ROOMNAME: {
            return {
                ...state,
                outputRoomname: action.outputRoomname
            };
        }
        case actionTypes.CHANGE_VOLUME: {
            return {
                ...state,
                volume: action.volume
            };
        }
        case actionTypes.CHANGE_TREBLE: {
            return {
                ...state,
                treble: action.treble
            };
        }
        case actionTypes.CHANGE_BASS: {
            return {
                ...state,
                bass: action.bass
            };
        }
        case actionTypes.SET_IS_CHECK_AUDIO: {
            return {
                ...state,
                isCheckAudio: action.isCheckAudio
            };
        }
        case actionTypes.SET_IS_CHECK_MIC: {
            return {
                ...state,
                isCheckMic: action.isCheckMic
            };
        }
        case actionTypes.UPDATE_AUDIO_TRACKS: {
            return {
                ...state,
                audioTracks: { ...action.audioTracks }
            };
        }
        case actionTypes.SET_HAND_OVER_READY: {
            return {
                ...state,
                isHandOverReady: action.isHandOverReady
            };
        }
        default:
            return state;
    }
};

export function* eventSaga() {
    yield takeEvery(actionTypes.ADD_STAT, function* postStat(action) {
        const response = yield post_stat(action.data);
        const data = yield response.data;
        yield put(actions.addStatSuccess(data.start_stat_id));
    });

    yield takeLatest(actionTypes.SHOW_TOOLBOX, function* showToolbox(action) {
        yield put(actions.setToolboxTimeout());
        yield delay(4000);
        yield put(actions.hideToolbox());
    });

    yield takeEvery(actionTypes.HIDE_TOOLBOX, function* hideToolbox(action) {
        yield put(actions.clearToolboxTimeout());
    });
}
