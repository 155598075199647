import { put, takeEvery } from "redux-saga/effects";

import { get_messages, update_message, add_message, remove_message } from "../../crud/message.crud";

export const actionTypes = {
    GET_ADMIN_MESSAGES: "GET_ADMIN_MESSAGES",
    RENDER_ADMIN_MESSAGE_LIST: "RENDER_ADMIN_MESSAGE_LIST",
    UPDATE_ADMIN_MESSAGE: "UPDATE_ADMIN_MESSAGE",
    UPDATE_ADMIN_MESSAGE_SUCCESS: "UPDATE_ADMIN_MESSAGE_SUCCESS",
    ADD_ADMIN_MESSAGE: "ADD_ADMIN_MESSAGE",
    ADD_ADMIN_MESSAGE_SUCCESS: "ADD_ADMIN_MESSAGE_SUCCESS",
    REMOVE_ADMIN_MESSAGE: "REMOVE_ADMIN_MESSAGE",
    REMOVE_ADMIN_MESSAGE_SUCCESS: "REMOVE_ADMIN_MESSAGE_SUCCESS"
};

export const actions = {
    getMessages: () => ({ type: actionTypes.GET_ADMIN_MESSAGES }),
    renderMessageList: messageList => ({
        type: actionTypes.RENDER_ADMIN_MESSAGE_LIST,
        messageList: messageList
    }),
    updateMessage: message => ({ type: actionTypes.UPDATE_ADMIN_MESSAGE, newMessage: message }),
    updateMessageSuccess: message => ({
        type: actionTypes.UPDATE_ADMIN_MESSAGE_SUCCESS,
        newMessage: message
    }),
    addMessage: message => ({ type: actionTypes.ADD_ADMIN_MESSAGE, newMessage: message }),
    addMessageSuccess: message => ({
        type: actionTypes.ADD_ADMIN_MESSAGE_SUCCESS,
        newMessage: message
    }),
    removeMessage: messageId => ({ type: actionTypes.REMOVE_ADMIN_MESSAGE, messageId: messageId }),
    removeMessageSuccess: messageId => ({
        type: actionTypes.REMOVE_ADMIN_MESSAGE_SUCCESS,
        messageId: messageId
    })
};

const initialMessagesState = {
    messageList: []
};

export const reducer = (state = initialMessagesState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_ADMIN_MESSAGE_LIST: {
            return {
                ...state,
                messageList: action.messageList
            };
        }
        case actionTypes.ADD_ADMIN_MESSAGE_SUCCESS: {
            let newMessageList = [
                ...state.messageList,
                {
                    ...action.newMessage
                }
            ];
            return {
                ...state,
                messageList: newMessageList
            };
        }
        case actionTypes.UPDATE_ADMIN_MESSAGE_SUCCESS: {
            let oldMessageList = state.messageList;
            const newMessageList = oldMessageList.map(message => {
                if (message.id === action.newMessage.id) {
                    return action.newMessage;
                } else {
                    return message;
                }
            });
            return {
                ...state,
                messageList: newMessageList
            };
        }
        case actionTypes.REMOVE_ADMIN_MESSAGE_SUCCESS: {
            let oldMessageList = [...state.messageList];
            const newMessageList = oldMessageList.filter(
                message => message.id !== action.messageId
            );
            return {
                ...state,
                messageList: newMessageList
            };
        }
        default:
            return state;
    }
};

export function* messagesSaga() {
    yield takeEvery(actionTypes.GET_ADMIN_MESSAGES, function* getMessagesSaga() {
        try {
            const response = yield get_messages();
            const data = yield response.data ?? [];
            yield put(actions.renderMessageList(data.messages));
        } catch {
            yield put(actions.renderMessageList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_ADMIN_MESSAGE, function* updateMessageSaga(action) {
        const newMessage = action.newMessage;
        yield update_message(newMessage.id, newMessage);
        yield put(actions.updateMessageSuccess(newMessage));
    });

    yield takeEvery(actionTypes.ADD_ADMIN_MESSAGE, function* addMessageSaga(action) {
        const newMessage = action.newMessage;
        const response = yield add_message(newMessage);
        yield put(actions.addMessageSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_ADMIN_MESSAGE, function* removeMessageSaga(action) {
        const messageId = action.messageId;
        yield remove_message(messageId);
        yield put(actions.removeMessageSuccess(messageId));
    });
}
