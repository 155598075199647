import React, { useEffect } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { colors } from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import _ from "lodash";
import { jitsiMeeting } from "../../../../components/JitsiMeeting";
import { UserRole } from "../../../../components/utils/UserRole";
import * as eventStore from "../../../../store/ducks/event.duck";
import AudioIndicator from "../../../../components/AudioIndicator";
import MicIndicator from "../../../../components/MicIndicator";
import ToggleButton from "../../../../components/ToggleButton";

const useStyles = makeStyles(theme => ({
    controlContainer: {
        backgroundColor: "#3c3731",
        color: colors.common.white,
        borderWidth: 4,
        borderColor: colors.common.white
    },
    mChannelContainer: {
        display: "none",
        [theme.breakpoints.down(1050)]: {
            display: "flex"
        }
    },
    channelContainer: {
        display: "flex",
        [theme.breakpoints.down(1050)]: {
            display: "none"
        }
    },
    infoContainer: {
        border: "solid 2px white",
        borderRadius: "50%",
        padding: 10,
        marginLeft: 48,
        marginRight: 48,
        // width: 145,
        height: 145,
        boxShadow: props =>
            props.isMicOn ? "0px 0px 20px 11px #b51f1f" : "0px 0px 20px 11px #1f99b5",
        [theme.breakpoints.down("lg")]: {
            border: "solid 2px white",
            borderRadius: "50%",
            padding: 10,
            marginLeft: 20,
            marginRight: 20,
            // width: 145,
            height: 153
        }
    },
    infoContainerItem: {
        marginLeft: 5,
        marginRight: 5
    },
    outputContainer: {
        [theme.breakpoints.down("xs")]: {
            width: "min-content"
        }
    },
    button: {
        margin: 3,
        padding: 5,
        color: colors.common.white,
        backgroundColor: colors.common.black,
        "&:hover": {
            backgroundColor: colors.grey[800]
        },
        "&:active": {
            backgroundColor: colors.grey[900]
        },
        minWidth: 48
    },
    btButton: {
        margin: 3,
        padding: 5
    },
    activeButton: {
        backgroundColor: colors.grey[700]
    },
    tableCell: {
        border: "none",
        color: colors.common.white,
        paddingTop: 0,
        paddingBottom: 0
    },
    divider: {
        width: "100%",
        marginTop: 0,
        marginBottom: 0,
        borderTop: "4px solid white",
        marginLeft: 0
    }
}));

const ORIGINAL = <FormattedMessage id="EVENT.LANG.ORIGINAL" />;
const ORIGINAL_ROOMNAME = "original";

function ChannelController(props) {
    const {
        event,
        user,
        participants,
        inputRoomname,
        outputRoomname,
        volume,
        isCheckAudio,
        isCheckMic,
        bass,
        treble,
        isMicOn,
        isMuted,
        clickedLanguageKey,
        audioLevels,
        outputChannel
    } = props;

    const classes = useStyles(props);
    const [otherInterRooms, setOtherInterRooms] = React.useState([]);
    const [emitterRoom, setEmitterRoom] = React.useState({});
    const [selfRoom, setSelfRoom] = React.useState({});
    const [self2Room, setSelf2Room] = React.useState({});
    const [time, setTime] = React.useState();

    useEffect(() => {
        if (event) {
            init();
        }
    }, [event]);

    useEffect(() => {
        const timer = setInterval(() => {
            const date = new Date();
            const hour = date.getHours();
            const min = date.getMinutes();
            setTime(`${hour < 10 ? "0" + hour : hour}:${min < 10 ? "0" + min : min}`);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        handleLangBtn(clickedLanguageKey);
    }, [clickedLanguageKey]);

    useEffect(() => {
        if (outputRoomname.includes(emitterRoom.room_name)) {
            changeOutputLang(selfRoom.room_name);
        } else if (outputRoomname.includes(selfRoom.room_name)) {
            if (!_.isEmpty(self2Room)) {
                changeOutputLang(self2Room.room_name);
            } else {
                changeOutputLang(emitterRoom.room_name);
            }
        } else if (outputRoomname.includes(self2Room.room_name)) {
            changeOutputLang(emitterRoom.room_name);
        }
    }, [outputChannel]);

    function init() {
        const event_rooms = event.event_rooms;
        const selfRoom = event.event_rooms.filter(
            event_room => event_room.interpreter_id === user.id
        );
        const emitterRoom = event.event_rooms.filter(event_room => event_room.interpreter_id === 0);

        if (user.interpreter.lang3) {
            const self2Room = event.event_rooms.find(
                event_room => event_room.output_lang_id === user.interpreter.lang3.id
            );
            if (self2Room) {
                setSelf2Room(self2Room);
            }
        }

        // setLiquidUrl(selfRoom[0].wss_url);

        var resArr = [];
        event_rooms.filter(function(item) {
            var i = resArr.findIndex(x => x.room_name === item.room_name);
            if (i <= -1) {
                resArr.push(item);
            }
            return null;
        });

        if (resArr) {
            const otherInterRooms = [];
            otherInterRooms.push(emitterRoom[0]);
            jitsiMeeting.setOutputRoomname(`non-${selfRoom[0].room_name}`);
            jitsiMeeting.setSelfRoomname(selfRoom[0].room_name);
            setSelfRoom(selfRoom[0]);
            for (var i = 0; i < resArr.length; i++) {
                const event_room = resArr[i];
                if (event_room.interpreter_id === 0) {
                    setEmitterRoom(event_room);
                    jitsiMeeting.changeLangTrack(ORIGINAL_ROOMNAME);
                } else {
                    otherInterRooms.push(event_room);
                }
            }
            setOtherInterRooms(otherInterRooms);
        }
    }

    function infoOutput(outputRoomname) {
        if (!outputRoomname) {
            return "";
        }
        if (outputRoomname.includes("non")) {
            return ORIGINAL;
        } else {
            if (emitterRoom.room_name && emitterRoom.room_name === outputRoomname) {
                return "OUT CH A " + emitterRoom.output_lang_short.toUpperCase();
            }

            if (selfRoom.room_name && selfRoom.room_name === outputRoomname) {
                return "OUT CH B " + selfRoom.output_lang_short.toUpperCase();
            }

            if (self2Room.room_name && self2Room.room_name === outputRoomname) {
                return "OUT CH C " + self2Room.output_lang_short.toUpperCase();
            }

            return "";
        }
    }

    function infoInput(inputRoomname) {
        if (inputRoomname === ORIGINAL_ROOMNAME) {
            return "IN 0 FLOOR";
        } else {
            const currentInputRoom = otherInterRooms.find(room => room.room_name === inputRoomname);
            if (currentInputRoom) {
                return currentInputRoom.output_lang_short
                    ? currentInputRoom.output_lang_short.toUpperCase()
                    : "";
            }
            return "";
        }
    }

    function _getLanguageChannelUserNumber(participants, room_name) {
        const originalOutputRoomname = room_name.replace("non-", "");
        return participants.filter(
            p =>
                p.getProperty("role") >= UserRole.USER &&
                p.getProperty("roomname") === originalOutputRoomname
        ).length;
    }

    function handleLangBtn(value) {
        if (value === 0) {
            jitsiMeeting.changeLangTrack(ORIGINAL_ROOMNAME);
            return;
        }
        if (otherInterRooms[value - 1]) {
            jitsiMeeting.changeLangTrack(otherInterRooms[value - 1].room_name);
        }
    }

    function _channelButton(value, currentInputRoomname) {
        let _class = classes.button;
        if (value === 0 && currentInputRoomname === ORIGINAL_ROOMNAME) {
            _class += ` ${classes.activeButton}`;
        } else if (
            currentInputRoomname &&
            otherInterRooms[value - 1] &&
            currentInputRoomname === otherInterRooms[value - 1].room_name
        ) {
            _class += ` ${classes.activeButton}`;
        }

        return (
            <Button
                key={value}
                variant="contained"
                color="secondary"
                className={_class}
                onClick={() => handleLangBtn(value)}
            >
                {value === 0
                    ? "0 Floor"
                    : otherInterRooms[value - 1]
                    ? `${value} ${
                          otherInterRooms[value - 1].output_lang_short
                              ? otherInterRooms[value - 1].output_lang_short.toUpperCase()
                              : " --"
                      }`
                    : `${value} --`}
            </Button>
        );
    }

    function changeOutputLang(roomname) {
        console.log("🚀 ~ file: ChannelController.js:289 ~ changeOutputLang ~ isMicOn:", isMicOn);
        if (!isMicOn) {
            roomname = `non-${roomname}`;
        }
        jitsiMeeting.setOutputRoomname(roomname);
    }

    function changeVolume(isUp) {
        jitsiMeeting.changeVolume(isUp ? 0.1 : -0.1);
    }

    function changeTreble(isUp) {
        jitsiMeeting.changeTreble(isUp ? 1 : -1);
    }

    function changeBass(isUp) {
        jitsiMeeting.changeBass(isUp ? 1 : -1);
    }

    const checkAudio = () => {
        props.setIsCheckAudio(!isCheckAudio);
    };

    const checkMic = () => {
        props.setIsCheckMic(!isCheckMic);
    };

    const getLocalAudioLevel = audioLevels => {
        let myUserId;
        try {
            myUserId = jitsiMeeting.room.myUserId();
        } catch (e) {
            return 0;
        }

        if (myUserId) {
            const localAudioLevel = audioLevels.find(a => a.userId === myUserId);
            if (localAudioLevel) {
                return localAudioLevel.audioLevel > 2 / 3 ? 100 : localAudioLevel.audioLevel * 150;
            }
        }

        return 0;
    };

    return (
        <Grid className={classes.controlContainer} container alignItems="center">
            <Grid
                container
                // wrap="nowrap"
                justifyContent="center"
                alignItems="center"
                className={classes.mChannelContainer}
            >
                {Array.from({ length: 21 }, (_, i) => i).map(
                    i =>
                        // <Grid key={i} container direction="column">
                        // [i, i + 1, i + 2, i + 3, i + 4].map(value =>
                        _channelButton(i, inputRoomname)
                    // )
                    // </Grid>
                )}
            </Grid>
            <Grid
                container
                wrap="nowrap"
                justifyContent="space-between"
                // direction="row"
            >
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid container direction="column" alignItems="flex-start" wrap="nowrap">
                        <Grid container wrap="nowrap" justifyContent="space-between">
                            <div style={{ position: "relative" }}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    style={{ lineHeight: "0.875rem" }}
                                    onClick={checkAudio}
                                >
                                    Check
                                    <br />
                                    Audio
                                </Button>
                                <AudioIndicator />
                            </div>
                            <div style={{ position: "relative" }}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    style={{ lineHeight: "0.875rem" }}
                                    onClick={checkMic}
                                >
                                    Check
                                    <br />
                                    Mic
                                </Button>
                                <MicIndicator audioLevel={getLocalAudioLevel(audioLevels)} />
                            </div>
                        </Grid>
                        <Grid container wrap="nowrap" justifyContent="space-between">
                            <Grid container wrap="nowrap" style={{ width: "auto" }}>
                                <Grid container item direction="column" justifyContent="center">
                                    {volume > 0.5 ? (
                                        <VolumeUpIcon />
                                    ) : volume === 0 ? (
                                        <VolumeMuteIcon />
                                    ) : (
                                        <VolumeDownIcon />
                                    )}
                                </Grid>
                                <Grid container item direction="column" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => changeVolume(true)}
                                    >
                                        +
                                    </Button>
                                    <hr className={classes.divider} />
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => changeVolume(false)}
                                    >
                                        -
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                style={{ width: "auto" }}
                            >
                                <Grid container wrap="nowrap" justifyContent="center">
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        style={{
                                            alignSelf: "center",
                                            fontSize: "0.8rem",
                                            marginLeft: 6,
                                            marginRight: 6
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Treble</span>
                                        <span className="d-block d-sm-none">T</span>
                                    </Grid>
                                    <Grid container direction="column" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={() => changeTreble(true)}
                                        >
                                            +
                                        </Button>
                                        <hr className={classes.divider} />
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={() => changeTreble(false)}
                                        >
                                            -
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container wrap="nowrap" justifyContent="center">
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        style={{
                                            alignSelf: "center",
                                            fontSize: "0.8rem",
                                            marginLeft: 6,
                                            marginRight: 6
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Bass</span>
                                        <span className="d-block d-sm-none">B</span>
                                    </Grid>
                                    <Grid container direction="column" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            color="primary"
                                            onClick={() => changeBass(true)}
                                        >
                                            +
                                        </Button>
                                        <hr className={classes.divider} />
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            color="primary"
                                            onClick={() => changeBass(false)}
                                        >
                                            -
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={classes.channelContainer}
                    >
                        {[1, 6, 11, 16].map(i => (
                            <Grid key={i} container direction="column">
                                {[i, i + 1, i + 2, i + 3, i + 4].map(value =>
                                    _channelButton(value, inputRoomname)
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        alignItems="center"
                        className={classes.infoContainer}
                    >
                        <Grid container direction="column" alignItems="center">
                            <Grid container alignItems="center" justifyContent="center">
                                <Grid className={classes.infoContainerItem}>
                                    {isMicOn ? (
                                        isMuted ? (
                                            <>
                                                <span
                                                    style={{
                                                        fontSize: 20,
                                                        verticalAlign: "middle"
                                                    }}
                                                >
                                                    MUTED
                                                </span>{" "}
                                                <span
                                                    style={{
                                                        verticalAlign: "middle"
                                                    }}
                                                >
                                                    {Math.round(volume * 100)}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <MicIcon
                                                    style={{
                                                        color: colors.red[500]
                                                    }}
                                                    fontSize="large"
                                                />
                                                {Math.round(volume * 100)}
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <MicOffIcon fontSize="large" />
                                            {Math.round(volume * 100)}
                                        </>
                                    )}
                                </Grid>
                                <Grid className={classes.infoContainerItem}>
                                    {isMicOn ? (
                                        isMuted ? (
                                            <>B: {Math.round((bass / 40) * 100)}</>
                                        ) : (
                                            <>B: {Math.round((bass / 40) * 100)}</>
                                        )
                                    ) : (
                                        <>B: {Math.round((bass / 40) * 100)}</>
                                    )}
                                </Grid>
                                <Grid className={classes.infoContainerItem}>
                                    {isMicOn ? (
                                        isMuted ? (
                                            <>T: {Math.round((treble / 40) * 100)}</>
                                        ) : (
                                            <>T: {Math.round((treble / 40) * 100)}</>
                                        )
                                    ) : (
                                        <>T: {Math.round((treble / 40) * 100)}</>
                                    )}
                                </Grid>
                            </Grid>

                            {infoInput(inputRoomname)}
                        </Grid>
                        <div style={{ fontSize: 20 }}>{time}</div>
                        <Grid container wrap="nowrap">
                            <Grid container direction="column" alignItems="center">
                                {infoOutput(outputRoomname)}
                            </Grid>
                            <Grid container direction="column" alignItems="center">
                                <PersonOutlineIcon />
                                {_getLanguageChannelUserNumber(participants, outputRoomname)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    className={classes.outputContainer}
                >
                    <Grid container direction="column" alignItems="flex-start">
                        <Grid container alignItems="flex-start">
                            <Button
                                variant="contained"
                                size="large"
                                className={`text-nowrap ${classes.button} ${
                                    outputRoomname.includes(emitterRoom.room_name)
                                        ? classes.activeButton
                                        : ""
                                }`}
                                onClick={() => {
                                    changeOutputLang(emitterRoom.room_name);
                                }}
                            >
                                Out CH A{" "}
                                {emitterRoom.output_lang_short
                                    ? emitterRoom.output_lang_short.toUpperCase()
                                    : ""}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                className={`${classes.button} ${
                                    outputRoomname.includes(emitterRoom.room_name)
                                        ? classes.activeButton
                                        : ""
                                } d-none d-lg-block`}
                                onClick={() => {
                                    changeOutputLang(emitterRoom.room_name);
                                }}
                            >
                                A
                            </Button>
                        </Grid>
                        <Grid container alignItems="flex-start">
                            <Button
                                variant="contained"
                                size="large"
                                className={`text-nowrap ${classes.button} ${
                                    outputRoomname.includes(selfRoom.room_name)
                                        ? classes.activeButton
                                        : ""
                                }`}
                                onClick={() => {
                                    changeOutputLang(selfRoom.room_name);
                                }}
                            >
                                Out CH B{" "}
                                {selfRoom.output_lang_short
                                    ? selfRoom.output_lang_short.toUpperCase()
                                    : ""}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                className={`${classes.button} ${
                                    outputRoomname.includes(selfRoom.room_name)
                                        ? classes.activeButton
                                        : ""
                                } d-none d-lg-block`}
                                onClick={() => {
                                    changeOutputLang(selfRoom.room_name);
                                }}
                            >
                                B
                            </Button>
                        </Grid>
                        <Grid container alignItems="flex-start">
                            <Button
                                variant="contained"
                                size="large"
                                className={`text-nowrap ${classes.button} ${
                                    self2Room && outputRoomname.includes(self2Room.room_name)
                                        ? classes.activeButton
                                        : ""
                                }`}
                                onClick={() => {
                                    if (!_.isEmpty(self2Room))
                                        changeOutputLang(self2Room.room_name);
                                }}
                            >
                                Out CH C{" "}
                                {self2Room.output_lang_short ? (
                                    self2Room.output_lang_short.toUpperCase()
                                ) : (
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                )}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                className={`${classes.button} ${
                                    self2Room && outputRoomname.includes(self2Room.room_name)
                                        ? classes.activeButton
                                        : ""
                                } d-none d-lg-block`}
                                onClick={() => {
                                    if (!_.isEmpty(self2Room))
                                        changeOutputLang(self2Room.room_name);
                                }}
                            >
                                C
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        participants: state.event.participants,
        user: state.auth.user,
        inputRoomname: state.event.inputRoomname,
        outputRoomname: state.event.outputRoomname,
        isMicOn: state.event.isMicOn,
        isMuted: state.event.isMuted,
        volume: state.event.volume,
        bass: state.event.bass,
        treble: state.event.treble,
        isCheckAudio: state.event.isCheckAudio,
        isCheckMic: state.event.isCheckMic,
        audioLevels: state.event.audioLevels
    };
};

const mapDispatchToProps = dispatch => ({
    setIsCheckAudio: isCheckAudio => dispatch(eventStore.actions.setIsCheckAudio(isCheckAudio)),
    setIsCheckMic: isCheckMic => dispatch(eventStore.actions.setIsCheckMic(isCheckMic))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChannelController));
