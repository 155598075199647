import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic";
import VideoTrack from "./VideoTrack";
import { jitsiMeeting } from "./JitsiMeeting";
import { VIRTUAL_BACKGROUNDS } from "./virtual-background/constants";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        minWidth: 120
    },
    bgContaier: {
        display: "inline-grid",
        width: "100%",
        gap: 4,
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"
    },
    bgItem: {
        maxWidth: 100,
        width: "100%",
        height: 54,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        boxSizing: "border-box",
        color: "white",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
            opacity: 0.5
        }
    },
    selected: {
        border: "solid 2px blue"
    },
    bgPreview: {
        backgroundColor: "gray",
        width: "100%",
        marginBottom: 10
    }
}));

function SelectBgDialog(props) {
    const {
        id,
        title,
        label,
        isOpen,
        eventBg,
        cameraSetting,
        currentValue,
        onChange,
        intl
    } = props;
    const [open, setOpen] = useState(false);
    const [bg, setBg] = useState("none");
    const [videoTrack, setVideoTrack] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (isOpen) setOpen(isOpen);
        return () => {
            disposePreview();
        };
    }, [isOpen, disposePreview]);

    useEffect(() => {
        if (currentValue != null && currentValue !== "none") {
            setBg(currentValue);
        }
    }, [currentValue]);

    useEffect(() => {
        // Fetch the video track when isOpen changes
        if (isOpen && cameraSetting) {
            const fetchVideoTrack = async () => {
                disposePreview();
                const background = await jitsiMeeting.getVirtualBackground(bg);
                const track = await jitsiMeeting.vbPreview(cameraSetting, background);
                console.log("🚀 ~ fetchVideoTrack ~ track:", track);
                setVideoTrack(track);
            };
            fetchVideoTrack();
        } else {
            disposePreview();
            setVideoTrack(null); // Reset video track when isOpen is false
        }
    }, [isOpen, cameraSetting, bg, disposePreview]);

    // Handle Cancel
    function handleCancel() {
        onChange(currentValue, false);
        setOpen(false);
    }

    // Handle Okay
    function handleOk(event) {
        disposePreview();
        onChange(bg, false);
        setOpen(false);
    }

    function handleClose(event, reason) {
        console.log(event);
        console.log(reason);
        disposePreview();
    }

    const handleClick = value => {
        setBg(value);
    };

    const disposePreview = async () => {
        if (videoTrack) {
            await videoTrack.setEffect(undefined);
            await videoTrack.dispose();
            console.log("🚀 ~ disposePreview ~ videoTrack:", videoTrack);
        }
    };

    return (
        <>
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                disablePortal
                style={{ position: "absolute" }}
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <VideoTrack
                            id="vb_preview"
                            className={classes.bgPreview}
                            muted={true}
                            videoTrack={{ jitsiTrack: videoTrack }}
                        />
                    </Grid>
                    <Grid container className={classes.bgContaier}>
                        {VIRTUAL_BACKGROUNDS.map(item => (
                            <Grid key={item.value}>
                                {item.image ? (
                                    <img
                                        className={`${classes.bgItem} ${
                                            bg === item.value ? classes.selected : ""
                                        }`}
                                        style={{
                                            backgroundColor: item.bgColor ?? "transparent"
                                        }}
                                        src={toAbsoluteUrl(item.image)}
                                        onClick={() => handleClick(item.value)}
                                        alt=""
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <div
                                        className={`${classes.bgItem} ${
                                            bg === item.value ? classes.selected : ""
                                        }`}
                                        style={{
                                            backgroundColor: item.bgColor ?? "transparent"
                                        }}
                                        onClick={() => handleClick(item.value)}
                                        aria-hidden="true"
                                    >
                                        {item.label}
                                    </div>
                                )}
                            </Grid>
                        ))}
                        {currentValue ? (
                            <Grid key={currentValue}>
                                <img
                                    className={`${classes.bgItem} ${
                                        bg === currentValue ? classes.selected : ""
                                    }`}
                                    src={process.env.REACT_APP_FILE_URL + currentValue}
                                    onClick={() => handleClick(currentValue)}
                                    aria-hidden="true"
                                    alt=""
                                />
                            </Grid>
                        ) : (
                            ""
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        {intl.formatMessage({ id: "EVENT.ACTION.CANCEL" })}
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        {intl.formatMessage({ id: "EVENT.ACTION.OK" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

SelectBgDialog.propTypes = {
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    cameraSetting: PropTypes.string.isRequired
};

export default injectIntl(SelectBgDialog);
