/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "./Portlet";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    }
}));

function Container({ children, beforeCodeTitle, handleSearch, handleAdd, intl }) {
    const classes = useStyles();

    function handleAddClick(event, value) {
        handleAdd(true);
    }
    return (
        <Portlet>
            <PortletHeader
                title={beforeCodeTitle}
                toolbar={
                    <PortletHeaderToolbar>
                        {handleSearch && (
                            <TextField
                                margin="dense"
                                variant="outlined"
                                type="search"
                                placeholder="search"
                                onChange={handleSearch}
                                className={classes.margin}
                            />
                        )}
                        {handleAdd && (
                            <Button
                                aria-label="Create new one"
                                variant="contained"
                                color="secondary"
                                onClick={e => handleAddClick(e)}
                            >
                                <AddCircleIcon />
                                <span style={{ paddingLeft: 4 }}>
                                    {intl.formatMessage({
                                        id: "EVENTS.CONTAINER.ADD_NEW_BTN"
                                    })}
                                </span>
                            </Button>
                        )}
                    </PortletHeaderToolbar>
                }
            />

            <PortletBody>
                {children && <div className="kt-portlet__preview">{children}</div>}
            </PortletBody>
        </Portlet>
    );
}

export default injectIntl(Container);
