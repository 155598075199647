import { put, takeEvery } from "redux-saga/effects";

import { get_trans, update_tran, add_tran, remove_inter } from "../../crud/tran.crud";
import { active_user } from "../../crud/admin.crud";
export const actionTypes = {
    GET_TRANS: "GET_TRANS",
    RENDER_TRAN_LIST: "RENDER_TRAN_LIST",
    UPDATE_TRANS: "UPDATE_TRANS",
    UPDATE_TRANS_SUCCESS: "UPDATE_TRANS_SUCCESS",
    ADD_TRAN: "ADD_TRAN",
    ADD_TRAN_SUCCESS: "ADD_TRAN_SUCCESS",
    REMOVE_TRAN: "REMOVE_TRAN",
    REMOVE_TRAN_SUCCESS: "REMOVE_TRAN_SUCCESS",
    ACTIVE_INTER: "ACTIVE_INTER",
    ACTIVE_INTER_SUCCESS: "ACTIVE_INTER_SUCCESS"
};

export const actions = {
    getTrans: () => ({ type: actionTypes.GET_TRANS }),
    renderTranList: tranList => ({ type: actionTypes.RENDER_TRAN_LIST, tranList: tranList }),
    updateTrans: tran => ({ type: actionTypes.UPDATE_TRANS, newTran: tran }),
    updateTransSuccess: tran => ({ type: actionTypes.UPDATE_TRANS_SUCCESS, newTran: tran }),
    addTran: tran => ({ type: actionTypes.ADD_TRAN, newTran: tran }),
    addTranSuccess: tran => ({ type: actionTypes.ADD_TRAN_SUCCESS, newTran: tran }),
    removeInter: tranId => ({ type: actionTypes.REMOVE_TRAN, tranId: tranId }),
    removeTranSuccess: tranId => ({ type: actionTypes.REMOVE_TRAN_SUCCESS, tranId: tranId }),
    activeInter: interId => ({ type: actionTypes.ACTIVE_INTER, interId: interId }),
    activeInterSuccess: (interId, userStatus) => ({
        type: actionTypes.ACTIVE_INTER_SUCCESS,
        interId: interId,
        userStatus: userStatus
    })
};

const initialTransState = {
    tranList: []
};

export const reducer = (state = initialTransState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_TRAN_LIST: {
            return {
                ...state,
                tranList: action.tranList
            };
        }
        case actionTypes.ADD_TRAN_SUCCESS: {
            let newTranList = [
                ...state.tranList,
                {
                    ...action.newTran
                }
            ];
            return {
                ...state,
                tranList: newTranList
            };
        }
        case actionTypes.UPDATE_TRANS_SUCCESS: {
            let oldTranList = state.tranList;
            const newTranList = oldTranList.map(tran => {
                if (tran.id === action.newTran.id) {
                    return action.newTran;
                } else {
                    return tran;
                }
            });
            return {
                ...state,
                tranList: newTranList
            };
        }
        case actionTypes.REMOVE_TRAN_SUCCESS: {
            let oldTranList = [...state.tranList];
            const newTranList = oldTranList.filter(tran => tran.id !== action.tranId);
            return {
                ...state,
                tranList: newTranList
            };
        }
        case actionTypes.ACTIVE_INTER_SUCCESS: {
            let oldInterList = [...state.tranList];
            const newInterList = oldInterList.map(inter => {
                if (inter.id === action.interId) {
                    inter.is_active = action.userStatus;
                }
                return inter;
            });
            return {
                ...state,
                tranList: newInterList
            };
        }
        default:
            return state;
    }
};

export function* transSaga() {
    yield takeEvery(actionTypes.GET_TRANS, function* getTransSaga() {
        try {
            const response = yield get_trans();
            const data = yield response.data ?? [];
            yield put(actions.renderTranList(data.trans));
        } catch {
            yield put(actions.renderTranList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_TRANS, function* updateTransSaga(action) {
        const newTran = action.newTran;
        yield update_tran(newTran.id, newTran);
        yield put(actions.updateTransSuccess(newTran));
    });

    yield takeEvery(actionTypes.ADD_TRAN, function* addTranSaga(action) {
        const newTran = action.newTran;
        const response = yield add_tran(newTran);
        yield put(actions.addTranSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_TRAN, function* removeTranSaga(action) {
        const tranId = action.tranId;
        yield remove_inter(tranId);
        yield put(actions.removeTranSuccess(tranId));
    });

    yield takeEvery(actionTypes.ACTIVE_INTER, function* activeInterSaga(action) {
        const id = action.interId;
        const response = yield active_user(id);
        yield put(actions.activeInterSuccess(id, response.data.user_status));
    });
}
