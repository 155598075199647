import { put, takeEvery } from "redux-saga/effects";

import { get_managers, update_manager, add_manager, remove_manager } from "../../crud/manager.crud";
import { active_user } from "../../crud/admin.crud";
export const actionTypes = {
    GET_MANAGERS: "GET_MANAGERS",
    RENDER_MANAGER_LIST: "RENDER_MANAGER_LIST",
    UPDATE_MANAGERS: "UPDATE_MANAGERS",
    UPDATE_MANAGERS_SUCCESS: "UPDATE_MANAGERS_SUCCESS",
    ADD_MANAGER: "ADD_MANAGER",
    ADD_MANAGER_SUCCESS: "ADD_MANAGER_SUCCESS",
    REMOVE_MANAGER: "REMOVE_MANAGER",
    REMOVE_MANAGER_SUCCESS: "REMOVE_MANAGER_SUCCESS",
    ACTIVE_MANAGER: "ACTIVE_MANAGER",
    ACTIVE_MANAGER_SUCCESS: "ACTIVE_MANAGER_SUCCESS"
};

export const actions = {
    getManagers: () => ({ type: actionTypes.GET_MANAGERS }),
    renderManagerList: managerList => ({
        type: actionTypes.RENDER_MANAGER_LIST,
        managerList: managerList
    }),
    updateManagers: manager => ({ type: actionTypes.UPDATE_MANAGERS, newManager: manager }),
    updateManagersSuccess: () => ({ type: actionTypes.UPDATE_MANAGERS_SUCCESS }),
    addManager: manager => ({ type: actionTypes.ADD_MANAGER, newManager: manager }),
    addManagerSuccess: manager => ({ type: actionTypes.ADD_MANAGER_SUCCESS, newManager: manager }),
    removeManager: managerId => ({ type: actionTypes.REMOVE_MANAGER, managerId: managerId }),
    removeManagerSuccess: managerId => ({
        type: actionTypes.REMOVE_MANAGER_SUCCESS,
        managerId: managerId
    }),
    activeManager: managerId => ({ type: actionTypes.ACTIVE_MANAGER, managerId: managerId }),
    activeManagerSuccess: (managerId, userStatus) => ({
        type: actionTypes.ACTIVE_MANAGER_SUCCESS,
        managerId: managerId,
        userStatus: userStatus
    })
};

const initialManagersState = {
    managerList: []
};

export const reducer = (state = initialManagersState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_MANAGER_LIST: {
            return {
                ...state,
                managerList: action.managerList
            };
        }
        case actionTypes.ADD_MANAGER_SUCCESS: {
            let newManagerList = [
                ...state.managerList,
                {
                    ...action.newManager
                }
            ];
            return {
                ...state,
                managerList: newManagerList
            };
        }
        case actionTypes.REMOVE_MANAGER_SUCCESS: {
            let oldManagerList = [...state.managerList];
            const newManagerList = oldManagerList.filter(
                manager => manager.id !== action.managerId
            );
            return {
                ...state,
                managerList: newManagerList
            };
        }
        case actionTypes.ACTIVE_MANAGER_SUCCESS: {
            let oldManagerList = [...state.managerList];
            const newManagerList = oldManagerList.map(manager => {
                if (manager.id === action.managerId) {
                    manager.is_active = action.userStatus;
                }
                return manager;
            });
            return {
                ...state,
                managerList: newManagerList
            };
        }
        default:
            return state;
    }
};

export function* managersSaga() {
    yield takeEvery(actionTypes.GET_MANAGERS, function* getManagersSaga() {
        try {
            const response = yield get_managers();
            const data = yield response.data ?? [];
            yield put(actions.renderManagerList(data.managers));
        } catch {
            yield put(actions.renderManagerList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_MANAGERS, function* updateManagersSaga(action) {
        const newManager = action.newManager;
        yield update_manager(newManager.id, newManager);
        yield put(actions.updateManagersSuccess());
    });

    yield takeEvery(actionTypes.ADD_MANAGER, function* addManagerSaga(action) {
        const newManager = action.newManager;
        const response = yield add_manager(newManager);
        yield put(actions.addManagerSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_MANAGER, function* removeManagerSaga(action) {
        const managerId = action.managerId;
        yield remove_manager(managerId);
        yield put(actions.removeManagerSuccess(managerId));
    });
    yield takeEvery(actionTypes.ACTIVE_MANAGER, function* activeManagerSaga(action) {
        const id = action.managerId;
        const response = yield active_user(id);
        yield put(actions.activeManagerSuccess(id, response.data.user_status));
    });
}
