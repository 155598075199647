import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Chat from "../../../../../components/Chat1";
import ToolBar from "../../../../../components/AToolbar";
import RemoteVideo from "../../../../../components/RemoteVideo";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import { calcSidePlaceHeight } from "../../../../../components/utils/VideoLayoutUtils";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import VideoTrack from "../../../../../components/VideoTrack";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles";
import useHandleMosaicView from "../../../../../components/useHandleMosaicView";
import useHandleMuteOthers from "../../../../../components/useHandleMuteOthers";

import * as eventStore from "../../../../../store/ducks/event.duck";
import * as messageStore from "../../../../../store/ducks/messages.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { EventType } from "../../../../../components/utils/EventType";

import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import AudioPlace from "../../../../../components/AudioPlace";
import useHandleMute from "../../../../../components/useHandleMute";

function MeetingVideo(props) {
    const {
        event,
        room,
        isJoining,
        isStream,
        isEndMeeting,
        roomname,
        user,
        authToken,
        addStat,
        startStatId,
        isMosaic,
        screenMode,
        isMuted,
        openChatBox,
        isChatBoxOpen,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        focusedId,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        isMuteAll,
        participants,
        setParticipants,
        messageList,
        getMessages,
        toParticipant,
        setToParticipant,
        audioLevels,
        localTracks,
        isOriginal,
        isSubTitle,
        intl
    } = props;

    const classes = useStyles(props);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        } else {
            startJitsiMeeting();
        }
    }, [isEndMeeting]);

    useEffect(() => {
        getMessages();

        calcSidePlaceHeight();
        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }
        };
    }, []);

    useEffect(() => {
        if (isJoining === 1) {
            // handleRoomJoined();
        }
    }, [isJoining]);

    useEffect(() => {
        if (jitsiMeeting.room) {
            postState(3, roomname);
            jitsiMeeting.changeLangTrack(roomname);
        }
    }, [roomname, room]);

    useEffect(() => {
        jitsiMeeting.setIsWithFloor(isOriginal);
    }, [isOriginal]);

    useHandleMute(isMuted);

    useHandleMosaicView(isMosaic, screenMode, participants);

    useEffect(() => {
        if (room) {
            room.setLocalParticipantProperty("stream", isStream ? 1 : 2);
        }
    }, [isStream]);

    useHandleMuteOthers(isMuteAll);

    async function startJitsiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, authToken, event, roomname, {
            isMicOn: true
        });
    }

    function allowUser(participantId) {
        const participant = jitsiMeeting.room.getParticipantById(participantId);
        if (participant) {
            if (parseInt(participant.getProperty("role")) === UserRole.OBSERVER) {
                jitsiMeeting.room.sendCommandOnce("moderator", {
                    value: participantId,
                    attributes: {
                        actionType: "allow",
                        content: 1
                    }
                });
            } else {
                jitsiMeeting.room.sendCommandOnce("moderator", {
                    value: participantId,
                    attributes: {
                        actionType: "allow",
                        content: 2
                    }
                });
            }
        }
    }

    function sendPreMsg(participantId, msg) {
        console.info(participantId, msg);
        room.sendCommandOnce("moderator", {
            value: participantId,
            attributes: {
                actionType: "notify",
                content: "msg",
                ...msg
            }
        });
    }

    function renderRemoteParticipant(p) {
        const pId = p.getId();
        const displayName = p.getDisplayName();

        const videoTrack = jitsiMeeting.remoteTracks[pId].find(
            t => t.getType() === "video" && !t.disposed
        );

        let videoElement = "";
        let placeClass = classes.participantContainer;
        if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
            placeClass += " interpreterPlace";
        }

        // Focus Element
        if (
            !(event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) &&
            pId === focusedId
        ) {
            placeClass += ` ${classes.participantContainerFocused}`;
        }

        let _isMuted = true;
        _isMuted = !!jitsiMeeting.remoteTracks[pId].find(t => t.getType() === "audio" && t.muted);

        if (jitsiMeeting.remoteTracks[pId]) {
            if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                placeClass += " interpreterPlace";
            }

            if (parseInt(p.getProperty("role")) !== UserRole.OBSERVER) {
                if (videoTrack) {
                    videoElement = (
                        <VideoTrack
                            id={`${pId}video`}
                            className={classes.video}
                            muted={true}
                            videoTrack={{ jitsiTrack: videoTrack }}
                        />
                    );

                    jitsiMeeting.setReceiverConstraints(pId);
                }
            }
        }

        // Participant's output language
        const p_output = p.getProperty("output");
        const is_non = p_output ? p_output.includes("non-") : false;

        let output_lang = null;
        if (is_non) {
            output_lang = { lang_name: "ORI" };
        } else {
            const p_output_room = event.event_rooms.find(
                event_room => event_room.room.room_name === p_output
            );

            output_lang = p_output_room ? p_output_room.room.output_lang : null;
        }

        return (
            <div
                key={pId}
                id={`${pId}Place`}
                className={placeClass}
                onClick={() => handleClickSidePlace(pId)}
            >
                <RemoteVideo
                    id={pId}
                    displayName={displayName}
                    allowUser={
                        event &&
                        event.event_type === EventType.WEBINAR &&
                        parseInt(p.getProperty("role")) >= UserRole.USER
                            ? allowUser
                            : null
                    }
                    baseClasses={classes}
                    muteParticipant={muteParticipant}
                    kickParticipant={kickParticipant}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    messageList={messageList}
                    lang={intl.locale}
                    sendPreMsg={sendPreMsg}
                    isNotify={true}
                    isMicOn={!is_non}
                    isMuted={_isMuted}
                    audioLevels={audioLevels}
                    isMosaic={isMosaic}
                    output_lang={output_lang}
                >
                    {videoElement}
                </RemoteVideo>
            </div>
        );
    }

    /**
     *
     */
    async function unload() {
        jitsiMeeting.unload();
    }

    function postState(status, _roomname) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === _roomname
        );
        const stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    function handleClickSidePlace(pId) {
        if (!jitsiMeeting.room) return;

        if (pId === "selfPlace") {
            jitsiMeeting.setFocusedId(jitsiMeeting.room.myUserId());
        } else {
            jitsiMeeting.setFocusedId(pId);
        }
        setParticipants(jitsiMeeting.room.getParticipants());
    }

    function _getBigVideoTrack() {
        let newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        if (!jitsiMeeting.room || !focusedId) return newBigVideoTrack;

        if (focusedId === jitsiMeeting.room.myUserId()) {
            newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        } else {
            const participant = jitsiMeeting.room.getParticipantById(focusedId);
            if (!participant) return null;
            const tracks = participant.getTracks();
            if (tracks.find(t => t.getType() === "video")) {
                newBigVideoTrack = tracks.find(t => t.getType() === "video");
            } else {
                newBigVideoTrack = null;
            }
        }

        return newBigVideoTrack;
    }

    function handleHoverEvent(event) {}

    function handleLeaveEvent(event) {}

    /**
     * Handle CameraSetting Change
     * @param {String} newCameraInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    /**
     * This function is called to mute a participant.
     * @param {*} id
     */
    function muteParticipant(id) {
        jitsiMeeting.room.muteParticipant(id);
    }

    /**
     * This function is called to kick participant.
     * @param {*} id
     */
    function kickParticipant(id) {
        console.info("kick", id);
        if (toParticipant === id) {
            setToParticipant("all");
        }
        jitsiMeeting.room.kickParticipant(id);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            jitsiMeeting.room.sendTextMessage(message);
        } else {
            jitsiMeeting.room.sendPrivateTextMessage(id, message);
        }
    }

    function validUsers(p) {
        const role = parseInt(p.getProperty("role"));
        return role > 0 && role !== UserRole.LITE_USER;
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                        : toAbsoluteUrl("/media/logos/logo-trans.png")
                                }
                                alt="avatar"
                            />
                        </div>

                        <ToolBar event={event} />

                        <VideoTrack
                            id="bigVideo"
                            className={classes.bigVideo}
                            muted={true}
                            videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                        />

                        <div className={isMosaic ? classes.mosaicOverlay : classes.hideSelfVideo} />
                        <div
                            id="sidePlace"
                            className={isMosaic ? classes.mosaicRemoteContainer : classes.sidePlace}
                        >
                            <div className="sideWrapper">
                                <div id="remotePlace" className={classes.remotePlace}>
                                    <div
                                        id="selfPlace"
                                        className={`${classes.participantContainer} ${
                                            jitsiMeeting.room &&
                                            focusedId === jitsiMeeting.room.myUserId()
                                                ? classes.participantContainerFocused
                                                : ""
                                        }`}
                                        onClick={() => handleClickSidePlace("selfPlace")}
                                    >
                                        <div className={classes.videoContainer}>
                                            <VideoTrack
                                                id="selfVideo"
                                                className={classes.video}
                                                muted={true}
                                                videoTrack={{
                                                    jitsiTrack: localTracks.find(
                                                        track => track.getType() === "video"
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="displayName">
                                            {user.name}(
                                            {intl.formatMessage({
                                                id: "CHAT.ME"
                                            })}
                                            )
                                        </div>
                                    </div>
                                    {participants
                                        .filter(p => validUsers(p))
                                        .map(p => renderRemoteParticipant(p))}
                                </div>
                            </div>
                        </div>
                        {Object.keys(jitsiMeeting.audioTracks).map(key => (
                            <AudioPlace
                                id={`${key}audio`}
                                key={key}
                                audioTrack={{
                                    jitsiTrack: jitsiMeeting.audioTracks[key].audioTrack
                                }}
                                participantId={key}
                                _muted={!!jitsiMeeting.audioTracks[key].muted}
                                _volume={
                                    jitsiMeeting.audioTracks[key].volume
                                        ? jitsiMeeting.audioTracks[key].volume
                                        : 0
                                }
                            />
                        ))}
                        <div id="chatContainer" className={classes.chatContainer}>
                            <Chat
                                open={isChatBoxOpen}
                                setIsChatDlg={openChatBox}
                                sendTextMessage={sendTextMessage}
                                participants={participants}
                            />
                        </div>

                        {isSubTitle && (
                            <div id="subTitleWrapper" className={classes.subTitleWrapper}>
                                <div id="subTitle" className={classes.subTitle}>
                                    {event.subtitle && event.subtitle !== "" && (
                                        <iframe
                                            title={event.title}
                                            src={event.subtitle}
                                            className={classes.subTitleIframe}
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        room: state.event.room,
        startStatId: state.event.startStatId,
        isJoining: state.event.isJoining,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        screenMode: state.event.screenMode,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isMuteAll: state.adminReducer.isMuteAll,
        participants: state.event.participants,
        toParticipant: state.event.toParticipant,
        isSidePlaceShow: state.event.isSidePlaceShow,
        messageList: state.messages.messageList,
        audioLevels: state.event.audioLevels,
        localTracks: state.event.localTracks,
        focusedId: state.event.focusedId,
        isSubTitle: state.event.isSubTitle
    };
};

const mapDispatchToProps = dispatch => ({
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setParticipants: participants => dispatch(eventStore.actions.setParticipants(participants)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant)),
    getMessages: () => dispatch(messageStore.actions.getMessages())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeetingVideo));
