/**
 *
 * @param {*} isMosaic if True, now layout is mosaic.
 * @param {*} currentPartitions
 */
export function calcWidth(isMosaic, currentPartitionsCount) {
    const mosaicELems = document.querySelectorAll("#remotePlace > div");

    let width = "100%";
    if (isMosaic) {
        let currentPLength = currentPartitionsCount + 1;

        const lengthRoot = Math.sqrt(currentPLength);
        if (Math.floor(lengthRoot) === lengthRoot) {
            currentPLength = lengthRoot;
        } else {
            currentPLength = Math.floor(lengthRoot) + 1;
        }
        // 56 * 16 / 9 = 100
        width = `calc(${100 / currentPLength}% - 20px)`;
    }

    for (let i = 0; i < mosaicELems.length; i++) {
        mosaicELems[i].style.width = width;
    }
}

export function calcSidePlaceHeight() {
    const remotePlaceElem = document.getElementById("remotePlace");
    const localPlaceElem = document.getElementById("localPlace");

    remotePlaceElem.style.maxHeight = `${localPlaceElem.offsetHeight}px`;
}
