import store from "../store/store";
import { useEffect } from "react";

function useHandleMuteOthers(isMuteAll) {
    useEffect(() => {
        const room = store.getState().event.room;
        if (room) {
            room.setStartMutedPolicy({
                audio: !room.getStartMutedPolicy().audio
            });
        }
    }, [isMuteAll]);
}

export default useHandleMuteOthers;
