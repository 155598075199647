import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles, lighten } from "@material-ui/core";
import {
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    TableSortLabel
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headRows,
        isCheckBox = true
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {isCheckBox && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "Select all desserts" }}
                        />
                    </TableCell>
                )}
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? "right" : "left"}
                        padding={row.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === row.id ? order : false}
                        className={row.className ?? ""}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell>
                    <FormattedMessage id="EVENTS.TABLE_HEADER.ACTIONS" />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headRows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            numeric: PropTypes.bool.isRequired,
            disablePadding: PropTypes.bool,
            label: PropTypes.string
        }).isRequired
    ).isRequired
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        minHeight: 0
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    }
}));

export const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
            variant="dense"
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle"></Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

export function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function searchArray(arrayV, key, keys = []) {
    return arrayV.filter(a => {
        let isIncluded = false;
        if (keys.length === 0) {
            isIncluded = true;
        } else {
            for (var i = 0; i < keys.length; i++) {
                const subKeys = keys[i].split(".");
                let eleSub = a;
                for (var j = 0; j < subKeys.length; j++) {
                    eleSub = eleSub[subKeys[j]];
                }
                if (eleSub) {
                    switch (keys[i]) {
                        case "is_active":
                            eleSub = eleSub === 1 ? "active" : "inactive";
                            break;
                        case "role":
                            eleSub =
                                eleSub === 6
                                    ? "general"
                                    : eleSub === 7
                                    ? "web"
                                    : eleSub === 8
                                    ? "lite"
                                    : "";
                            break;
                        default:
                            break;
                    }

                    isIncluded = eleSub
                        .toString()
                        .toLowerCase()
                        .includes(key.toLowerCase());

                    if (isIncluded) {
                        break;
                    }
                }
            }
        }
        return isIncluded;
    });
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
