import { apiGetRequest } from "./helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DASHBOARD_URL = BASE_URL + "dashboard";
export const TRAN_EDIT_URL = BASE_URL + "trans";

export function get_stat_conferences_counts() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_conferences_counts");
}

export function get_stat_events() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_events");
}

export function get_stat_events_details() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_events_details");
}

export function get_stat_devices() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_devices");
}

export function get_stat_systems() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_systems");
}

export function get_stat_browsers() {
    return apiGetRequest(DASHBOARD_URL + "/get_stat_browsers");
}
