import { put, takeEvery } from "redux-saga/effects";

import { get_emitters, update_emitter, add_emitter, remove_emitter } from "../../crud/emitter.crud";
import { active_user } from "../../crud/admin.crud";
export const actionTypes = {
    GET_EMITTERS: "GET_EMITTERS",
    RENDER_EMITTER_LIST: "RENDER_EMITTER_LIST",
    UPDATE_EMITTERS: "UPDATE_EMITTERS",
    UPDATE_EMITTERS_SUCCESS: "UPDATE_EMITTERS_SUCCESS",
    ADD_EMITTER: "ADD_EMITTER",
    ADD_EMITTER_SUCCESS: "ADD_EMITTER_SUCCESS",
    REMOVE_EMITTER: "REMOVE_EMITTER",
    REMOVE_EMITTER_SUCCESS: "REMOVE_EMITTER_SUCCESS",
    ACTIVE_EMITTER: "ACTIVE_EMITTER",
    ACTIVE_EMITTER_SUCCESS: "ACTIVE_EMITTER_SUCCESS"
};

export const actions = {
    getEmitters: () => ({ type: actionTypes.GET_EMITTERS }),
    renderEmitterList: emitterList => ({
        type: actionTypes.RENDER_EMITTER_LIST,
        emitterList: emitterList
    }),
    updateEmitters: emitter => ({ type: actionTypes.UPDATE_EMITTERS, newEmitter: emitter }),
    updateEmittersSuccess: () => ({ type: actionTypes.UPDATE_EMITTERS_SUCCESS }),
    addEmitter: emitter => ({ type: actionTypes.ADD_EMITTER, newEmitter: emitter }),
    addEmitterSuccess: emitter => ({ type: actionTypes.ADD_EMITTER_SUCCESS, newEmitter: emitter }),
    removeEmitter: emitterId => ({ type: actionTypes.REMOVE_EMITTER, emitterId: emitterId }),
    removeEmitterSuccess: emitterId => ({
        type: actionTypes.REMOVE_EMITTER_SUCCESS,
        emitterId: emitterId
    }),
    activeEmitter: emitterId => ({ type: actionTypes.ACTIVE_EMITTER, emitterId: emitterId }),
    activeEmitterSuccess: (emitterId, userStatus) => ({
        type: actionTypes.ACTIVE_EMITTER_SUCCESS,
        emitterId: emitterId,
        userStatus: userStatus
    })
};

const initialEmittersState = {
    emitterList: []
};

export const reducer = (state = initialEmittersState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_EMITTER_LIST: {
            return {
                ...state,
                emitterList: action.emitterList
            };
        }
        case actionTypes.ADD_EMITTER_SUCCESS: {
            let newEmitterList = [
                ...state.emitterList,
                {
                    ...action.newEmitter
                }
            ];
            return {
                ...state,
                emitterList: newEmitterList
            };
        }
        case actionTypes.REMOVE_EMITTER_SUCCESS: {
            let oldEmitterList = [...state.emitterList];
            const newEmitterList = oldEmitterList.filter(
                emitter => emitter.id !== action.emitterId
            );
            return {
                ...state,
                emitterList: newEmitterList
            };
        }
        case actionTypes.ACTIVE_EMITTER_SUCCESS: {
            let oldEmitterList = [...state.emitterList];
            const newEmitterList = oldEmitterList.map(emitter => {
                if (emitter.id === action.emitterId) {
                    emitter.is_active = action.userStatus;
                }
                return emitter;
            });
            return {
                ...state,
                emitterList: newEmitterList
            };
        }
        default:
            return state;
    }
};

export function* emittersSaga() {
    yield takeEvery(actionTypes.GET_EMITTERS, function* getEmittersSaga() {
        try {
            const response = yield get_emitters();
            const data = yield response.data ?? [];
            yield put(actions.renderEmitterList(data.emitters));
        } catch {
            yield put(actions.renderEmitterList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_EMITTERS, function* updateEmittersSaga(action) {
        const newEmitter = action.newEmitter;
        yield update_emitter(newEmitter.id, newEmitter);
        yield put(actions.updateEmittersSuccess());
    });

    yield takeEvery(actionTypes.ADD_EMITTER, function* addEmitterSaga(action) {
        const newEmitter = action.newEmitter;
        const response = yield add_emitter(newEmitter);
        yield put(actions.addEmitterSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_EMITTER, function* removeEmitterSaga(action) {
        const emitterId = action.emitterId;
        yield remove_emitter(emitterId);
        yield put(actions.removeEmitterSuccess(emitterId));
    });
    yield takeEvery(actionTypes.ACTIVE_EMITTER, function* activeEmitterSaga(action) {
        const id = action.emitterId;
        const response = yield active_user(id);
        yield put(actions.activeEmitterSuccess(id, response.data.user_status));
    });
}
