import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { colors } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import GroupTranslatorRemoteVideo from "./GroupTranslatorRemoteVideo";
import * as eventStore from "../../../../store/ducks/event.duck";

const useStyles = makeStyles(theme => ({
    groupTranslatorContainer: {
        // position: "absolute",
        width: "100%",
        // minWidth: "16rem",
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 4,
        // transform: props => { return `translate3d(0px, ${_getInterpreters(props.participants).length * -40}px, 0px)` },
        // boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        padding: "1rem",
        // marginTop: "-100%",
        bottom: 33
        // display: props => (props.isGroupTranslator ? "block" : "none")
    },
    videoContainer: {
        display: "flex",
        paddingTop: "75%"
    },
    videoElement: {
        width: "100%",
        marginTop: "-75%"
    },
    participantContainer: {
        display: "block",
        position: "relative",
        borderRadius: 4,
        cursor: "pointer",
        background: colors.common.black,
        margin: 2,
        marginTop: 10
    }
}));

function GroupTranslator(props) {
    const { isGroupTranslator, openChatBox, groupTranslator, setToParticipant, event } = props;
    const classes = useStyles(props);
    console.log(event);
    return (
        <Paper className={classes.groupTranslatorContainer}>
            {groupTranslator.length > 0 ? (
                <>
                    {groupTranslator.map(participant => (
                        <div
                            id={`${participant.getId()}CabinPlace`}
                            className={classes.participantContainer}
                            key={participant.getId()}
                        >
                            <GroupTranslatorRemoteVideo
                                id={participant.getId()}
                                displayName={participant.getDisplayName()}
                                baseClasses={classes}
                                openChatBox={openChatBox}
                                setToParticipant={setToParticipant}
                                // messageList={messageList}
                                // lang={intl.locale}
                                participant={participant}
                                // isNotify={true}
                                event={event}
                            />
                        </div>
                    ))}
                </>
            ) : (
                "There are no other active interpreters on this output channel"
            )}
            <div id="translatorVideoContainer"></div>
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        isGroupTranslator: state.event.isGroupTranslator,
        participants: state.event.participants,
        groupTranslator: state.event.groupTranslator,
        event: state.interpreterReducer.event
    };
};

const mapDispatchToProps = dispatch => ({
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant))
    // hideNotification: () => dispatch(eventStore.actions.hideNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupTranslator);
