import axios from "axios";
import { apiGetRequest, apiPostRequest } from "../helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ADMINS_URL = BASE_URL + "admin";
export const EVENT_URL = BASE_URL + "event_list";
export const EVENT_EDIT_URL = BASE_URL + "events";

export function get_events() {
    return apiGetRequest(ADMINS_URL + "/event_list");
}

export function get_event(id) {
    return apiGetRequest(ADMINS_URL + `/events/${id}`);
}

export function update_event(id, newTran) {
    return apiPostRequest(EVENT_EDIT_URL + `/${id}`, newTran);
}

export function add_event(event) {
    return apiPostRequest(BASE_URL + `create_event`, event);
}

export function remove_event(id) {
    return apiPostRequest(BASE_URL + `delete_event/${id}`);
}

export function upload_event_pic(data) {
    return axios.post(BASE_URL + "upload_event_pic", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export function get_participants_list(eventId) {
    return apiGetRequest(BASE_URL + `events/${eventId}/participants`);
}

export function add_participants(eventId, userIds) {
    return apiPostRequest(BASE_URL + `events/${eventId}/add_participants`, { userIds: userIds });
}

export function remove_participant(participantId) {
    return apiPostRequest(BASE_URL + `event/remove_participant`, { participantId: participantId });
}
