import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    TablePagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Container from "../../../../partials/content/Container";
import {
    EnhancedTableToolbar,
    EnhancedTableHead,
    stableSort,
    getSorting,
    searchArray
} from "../../../../components/EnhancedTable";
import * as channelsStore from "../../../../store/ducks/channels.duck";
import ChannelDlg from "./ChannelDlg";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    }
}));

function Channels(props) {
    const { channelList, getChannels, intl } = props;

    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Table Keys
    const [openFormDlg, setOpenFormDlg] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    const [clickedValue, setClickedValue] = React.useState(-1);
    const [clickedChannelValue, setClickedChannelValue] = React.useState({});
    const [searchKey, setSearchKey] = React.useState("");

    const headRows = [
        {
            id: "channel_name",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "CHANNEL.FIELD.NAME" })
        },
        {
            id: "channel_detail",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "CHANNEL.FIELD.DETAIL" })
        }
    ];

    useEffect(() => {
        getChannels();
    }, [getChannels]);

    // Handle Tables Events
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, channelList.length - page * rowsPerPage);
    const searchable = ["channel_name", "channel_detail"];

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = channelList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleAdd(event) {
        setOpenFormDlg(true);
    }

    function handleEdit(event, id) {
        const selectedChannel = _.find(channelList, ["id", id]);
        setClickedChannelValue(selectedChannel);
        setClickedValue(id);
        setOpenFormDlg(true);
    }

    function handleDeleteClick(event, id) {
        setClickedValue(id);
        setOpenDel(true);
    }

    function handleCloseDel(value) {
        setOpenDel(false);
    }

    function handleClose(value) {
        setClickedChannelValue({});
        setClickedValue(-1);
        setOpenFormDlg(false);
    }

    function handleDelete(event) {
        props.removeChannel(clickedValue);
        setOpenDel(false);
    }

    const onHandleChange = e => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Container
                        beforeCodeTitle={intl.formatMessage({ id: "MENU.SETTINGS.CHANNELS" })}
                        handleSearch={onHandleChange}
                        handleAdd={handleAdd}
                    >
                        <div className="kt-section">
                            <div className="kt-section__content">
                                <div className={classes.root}>
                                    <Paper className={classes.paper}>
                                        <EnhancedTableToolbar numSelected={selected.length} />
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                size="medium"
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={channelList.length}
                                                    headRows={headRows}
                                                />
                                                <TableBody>
                                                    {stableSort(
                                                        searchArray(
                                                            channelList,
                                                            searchKey,
                                                            searchable
                                                        ),
                                                        getSorting(order, orderBy)
                                                    )
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(
                                                                row.id
                                                            );
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                    selected={isItemSelected}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            checked={isItemSelected}
                                                                            onClick={event =>
                                                                                handleCheckClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-labelledby": labelId
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        id={labelId}
                                                                        scope="row"
                                                                        padding="none"
                                                                    >
                                                                        {row.channel_name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.channel_detail}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="Edit"
                                                                            onClick={event =>
                                                                                handleEdit(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>

                                                                        <IconButton
                                                                            aria-label="Delete"
                                                                            onClick={event =>
                                                                                handleDeleteClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{ height: 49 * emptyRows }}
                                                        >
                                                            <TableCell colSpan={8} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={
                                                searchArray(channelList, searchKey, searchable)
                                                    .length
                                            }
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page"
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </div>
                            </div>
                            <div>
                                <ChannelDlg
                                    channelId={clickedValue}
                                    channelValue={clickedChannelValue}
                                    open={openFormDlg}
                                    onClose={handleClose}
                                />
                            </div>
                            <div>
                                <Dialog
                                    open={openDel}
                                    onClose={handleCloseDel}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {intl.formatMessage({ id: "CHANNEL.ALERT.DEL.DESC" })}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDel} color="primary">
                                            {intl.formatMessage({ id: "EVENT.ACTION.CANCEL" })}
                                        </Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>
                                            {intl.formatMessage({ id: "DIALOG.ACTION.CONTINUE" })}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

Channels.propTypes = {
    channelList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            channel_name: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
};

const mapStateToProps = state => {
    return {
        channelList: state.channels.channelList
    };
};

export default injectIntl(connect(mapStateToProps, channelsStore.actions)(Channels));
