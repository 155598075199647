import { put, takeEvery } from "redux-saga/effects";

import {
    get_admins,
    update_admin,
    add_admin,
    remove_admin,
    active_user
} from "../../crud/admin.crud";

export const actionTypes = {
    GET_ADMINS: "GET_ADMINS",
    RENDER_ADMIN_LIST: "RENDER_ADMIN_LIST",
    UPDATE_ADMINS: "UPDATE_ADMINS",
    UPDATE_ADMINS_SUCCESS: "UPDATE_ADMINS_SUCCESS",
    ADD_ADMIN: "ADD_ADMIN",
    ADD_ADMIN_SUCCESS: "ADD_ADMIN_SUCCESS",
    REMOVE_ADMIN: "REMOVE_ADMIN",
    REMOVE_ADMIN_SUCCESS: "REMOVE_ADMIN_SUCCESS",
    ACTIVE_ADMIN: "ACTIVE_ADMIN",
    ACTIVE_ADMIN_SUCCESS: "ACTIVE_ADMIN_SUCCESS"
};

export const actions = {
    getAdmins: () => ({ type: actionTypes.GET_ADMINS }),
    renderAdminList: adminList => ({ type: actionTypes.RENDER_ADMIN_LIST, adminList: adminList }),
    updateAdmins: admin => ({ type: actionTypes.UPDATE_ADMINS, newAdmin: admin }),
    updateAdminsSuccess: () => ({ type: actionTypes.UPDATE_ADMINS_SUCCESS }),
    addAdmin: admin => ({ type: actionTypes.ADD_ADMIN, newAdmin: admin }),
    addAdminSuccess: admin => ({ type: actionTypes.ADD_ADMIN_SUCCESS, newAdmin: admin }),
    removeAdmin: adminId => ({ type: actionTypes.REMOVE_ADMIN, adminId: adminId }),
    removeAdminSuccess: adminId => ({ type: actionTypes.REMOVE_ADMIN_SUCCESS, adminId: adminId }),
    activeAdmin: adminId => ({ type: actionTypes.ACTIVE_ADMIN, adminId: adminId }),
    activeAdminSuccess: (adminId, userStatus) => ({
        type: actionTypes.ACTIVE_ADMIN_SUCCESS,
        adminId: adminId,
        userStatus: userStatus
    })
};

const initialAdminsState = {
    adminList: []
};

export const reducer = (state = initialAdminsState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_ADMIN_LIST: {
            return {
                ...state,
                adminList: action.adminList
            };
        }
        case actionTypes.ADD_ADMIN_SUCCESS: {
            let newAdminList = [
                ...state.adminList,
                {
                    ...action.newAdmin
                }
            ];
            return {
                ...state,
                adminList: newAdminList
            };
        }
        case actionTypes.REMOVE_ADMIN_SUCCESS: {
            let oldAdminList = [...state.adminList];
            const newAdminList = oldAdminList.filter(admin => admin.id !== action.adminId);
            return {
                ...state,
                adminList: newAdminList
            };
        }
        case actionTypes.ACTIVE_ADMIN_SUCCESS: {
            let oldAdminList = [...state.adminList];
            const newAdminList = oldAdminList.map(admin => {
                if (admin.id === action.adminId) {
                    admin.is_active = action.userStatus;
                }
                return admin;
            });
            return {
                ...state,
                adminList: newAdminList
            };
        }
        default:
            return state;
    }
};

export function* adminsSaga() {
    yield takeEvery(actionTypes.GET_ADMINS, function* getAdminsSaga() {
        try {
            const response = yield get_admins();
            const data = yield response.data;
            yield put(actions.renderAdminList(data.admins));
        } catch {
            yield put(actions.renderAdminList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_ADMINS, function* updateAdminsSaga(action) {
        const newAdmin = action.newAdmin;
        yield update_admin(newAdmin.id, newAdmin);
        yield put(actions.updateAdminsSuccess());
    });

    yield takeEvery(actionTypes.ADD_ADMIN, function* addAdminSaga(action) {
        const newAdmin = action.newAdmin;
        const response = yield add_admin(newAdmin);
        yield put(actions.addAdminSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_ADMIN, function* removeAdminSaga(action) {
        const adminId = action.adminId;
        yield remove_admin(adminId);
        yield put(actions.removeAdminSuccess(adminId));
    });

    yield takeEvery(actionTypes.ACTIVE_ADMIN, function* activeAdminSaga(action) {
        const id = action.adminId;
        const response = yield active_user(id);
        yield put(actions.activeAdminSuccess(id, response.data.user_status));
    });
}
