import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import {
    Grid,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    makeStyles,
    colors
} from "@material-ui/core";

import * as transStore from "../../../../store/ducks/trans.duck";

const blue = colors.blue;
const useDlgStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600]
    },
    formControl: {
        minWidth: 250,
        maxWidth: 400
    },
    formLangControl: {
        minWidth: 150,
        maxWidth: 300
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

function InterpreterDlg(props) {
    const { onClose, open, tranId, tranValue, languages, dispatch, intl } = props;

    const [tran, setTran] = useState({ name: "", email: "" });

    const classes = useDlgStyles();

    useEffect(() => {
        if (tranId && tranId !== -1) {
            if (tranValue && Object.keys(tranValue).length !== 0) setTran(tranValue);
        }
    }, [tranId, tranValue]);

    const handleClose = () => {
        setTran({ name: "", email: "" });
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "INTERPRETER.DETAIL.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={tran}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.lang1_id) {
                            errors.lang1_id = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.lang2_id) {
                            errors.lang2_id = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        if (values.lang3_id) {
                            const lang3 = languages.find(r => r.id === values.lang3_id);
                            if (lang3) {
                                values.lang3_name = lang3.lang_label;
                            } else {
                                values.lang3_name = null;
                            }
                        } else {
                            values.lang3_name = null;
                        }
                        enableLoading();
                        setTimeout(() => {
                            if (tranId !== -1) {
                                dispatch(
                                    transStore.actions.updateTrans(Object.assign(tranValue, values))
                                );
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(transStore.actions.addTran(values));
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.NAME"
                                    })}
                                    className="kt-width-full"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.EMAIL"
                                    })}
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <div className="form-group">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            error={Boolean(errors.lang1_id)}
                                        >
                                            <InputLabel id="input-lang-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.INPUT_LANG"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="input-lang-checkbox-label"
                                                id="in-lang-checkbox"
                                                value={values.lang1_id ? values.lang1_id : ""}
                                                onChange={handleChange}
                                                name="lang1_id"
                                            >
                                                {languages.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.lang_label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.lang1_id}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            error={Boolean(errors.lang2_id)}
                                        >
                                            <InputLabel id="output-lang-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.OUTPUT_LANG"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="output-lang-checkbox-label"
                                                id="out-lang-checkbox"
                                                value={values.lang2_id ? values.lang2_id : ""}
                                                onChange={handleChange}
                                                name="lang2_id"
                                            >
                                                {languages.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.lang_label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.lang2_id}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            error={Boolean(errors.lang3_id)}
                                        >
                                            <InputLabel id="other-lang-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.OTHER_LANG"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="other-lang-checkbox-label"
                                                id="other-lang-checkbox"
                                                value={values.lang3_id ? values.lang3_id : ""}
                                                onChange={handleChange}
                                                name="lang3_id"
                                            >
                                                <MenuItem key={-1} value={null}>
                                                    <em>
                                                        {intl.formatMessage({
                                                            id: "SELECT.NO_VALUE"
                                                        })}
                                                    </em>
                                                </MenuItem>
                                                {languages.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.lang_label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.lang3_id}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

InterpreterDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    tranId: PropTypes.any
};

export default injectIntl(connect()(InterpreterDlg));
