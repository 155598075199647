export const UserRole = {
    SUPERADMIN: 1,
    ADMIN: 2,
    EVENT_MANAGER: 3,
    EMITTER: 4,
    INTERPRETER: 5,
    USER: 6,
    WEB_USER: 7,
    LITE_USER: 8,
    OBSERVER: 9
};
