import { apiGetRequest, apiPostRequest } from "./helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ADMIN_URL = BASE_URL + "admin_list";
export const ADMIN_EDIT_URL = BASE_URL + "admins";
export const STAT_URL = BASE_URL + "add_status";

export function get_admins() {
    return apiGetRequest(ADMIN_URL);
}

export function get_admin(id) {
    return apiGetRequest(ADMIN_EDIT_URL + `/${id}`);
}

export function update_admin(id, newTran) {
    return apiPostRequest(ADMIN_EDIT_URL + `/${id}`, newTran);
}

export function add_admin(admin) {
    return apiPostRequest(BASE_URL + `create_admin`, admin);
}

export function remove_admin(id) {
    return apiPostRequest(BASE_URL + `delete_admin/${id}`);
}

export function active_user(id) {
    return apiPostRequest(BASE_URL + `active_user/${id}`);
}
