import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    TablePagination,
    Button,
    Icon
} from "@material-ui/core";
import {
    EnhancedTableHead,
    stableSort,
    getSorting,
    searchArray
} from "../../../../components/EnhancedTable";
import Container from "../../../../partials/content/Container";
import * as eventsStore from "../../../../store/ducks/emitters/index.duck";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    button: {
        margin: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
}));

function EventList(props) {
    const { eventList, getEvents, intl } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchKey, setSearchKey] = React.useState("");
    const searchable = ["title", "detail", "start_datetime"];

    const headRows = [
        {
            id: "title",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.TITLE" })
        },
        {
            id: "detail",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.DETAIL" })
        },
        {
            id: "start_datetime",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.START_DATE" })
        }
    ];

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    // Handle Tables Events
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = eventList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleEdit(event, id) {
        props.history.push(`events/${id}`);
    }

    function convertApiToLocalTime(start_datetime) {
        if (start_datetime) {
            const time = new Date(start_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return start_datetime.split(/[\sT]+/)[0] + " " + time;
        } else {
            return "";
        }
    }

    const onHandleChange = e => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Container
                        beforeCodeTitle={intl.formatMessage({
                            id: "EVENTS.CONTAINER.TITLE"
                        })}
                        handleSearch={onHandleChange}
                    >
                        <Paper className={classes.paper}>
                            <div className={classes.tableWrapper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size="medium"
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={eventList.length}
                                        headRows={headRows}
                                    />
                                    <TableBody>
                                        {stableSort(
                                            searchArray(eventList, searchKey, searchable),
                                            getSorting(order, orderBy)
                                        )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                onClick={event =>
                                                                    handleCheckClick(event, row.id)
                                                                }
                                                                inputProps={{
                                                                    "aria-labelledby": labelId
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.title}

                                                            <div className="mt-2 d-block d-sm-none">
                                                                {row.detail}
                                                            </div>
                                                            <div className="mt-2 d-block d-sm-none">
                                                                {convertApiToLocalTime(
                                                                    row.start_datetime
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="d-none d-sm-table-cell px-0">
                                                            {row.detail}
                                                        </TableCell>
                                                        <TableCell className="d-none d-sm-table-cell px-0">
                                                            {convertApiToLocalTime(
                                                                row.start_datetime
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                className={classes.button}
                                                                onClick={event =>
                                                                    handleEdit(event, row.id)
                                                                }
                                                            >
                                                                {intl.formatMessage({
                                                                    id: "EVENTS.TABLE_HEADER.DETAIL"
                                                                })}
                                                                <Icon className={classes.rightIcon}>
                                                                    phone
                                                                </Icon>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 49 * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={8} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={
                                    searchArray(eventList, searchKey, searchable)
                                        ? searchArray(eventList, searchKey, searchable).length
                                        : 0
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Container>
                </div>
            </div>
        </>
    );
}

EventList.propTypes = {
    eventList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
};

const mapStateToProps = state => {
    return {
        eventList: state.emitterReducer.eventList
    };
};

const mapDispatchToProps = dispatch => ({
    getEvents: () => dispatch(eventsStore.actions.getEmitterEvents())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventList));
